import React from "react";
import Carousel from 'react-grid-carousel';


function HomeTestimonial() {
  return (
    <>
    <section className="space-top space-extra-bottom"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/bg/testi-bg-2-1.jpg)`,
      backgroundSize: 'cover',
  }}>
  
  <div className="container">
    <div className="row justify-content-center text-center">
      <div
        className="col-md-9 col-lg-7 col-xl-6 wow fadeInUp"
        data-wow-delay="0.2s"
      >
        <div className="title-area">
          <h2 className="sec-title4" style={{color: "white"}}>Client's Review</h2>
          <p className="sec-text" style={{color: "white"}}>
          "Aayush Food Products: Taste the Difference! Our satisfied customers share their experiences in brief testimonials, showcasing the delicious flavors and superior quality of our products. Join the chorus of happy consumers and indulge in the goodness of Aayush today!"
          </p>
        </div>
      </div>
    </div>
    <div className="row">
     
  <Carousel cols={3} rows={1} gap={10} loop>   
    <Carousel.Item>
  <div className="col-lg-12">
    <div className="testi-style3">
      <span className="testi-icon">
        <i className="fas fa-quote-right" />
      </span>
      <div className="testi-avater">
        <img
          src="assets/img/testi/testi-auth-3-1.png"
          alt="Testimonial Author"
        />
      </div>
      <div className="testi-content">
        <div className="testi-rating">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
        </div>
        <p className="testi-text">
        The quality of the product & customer service was good. They have wide range of spices, herbs and edible flowers variety. I have a good experience with them
        </p>
        <h3 className="testi-name">Priya Susila</h3>
        
      </div>
    </div>
  </div>
  </Carousel.Item> 
  <Carousel.Item>
  <div className="col-lg-12">
    <div className="testi-style3">
      <span className="testi-icon">
        <i className="fas fa-quote-right" />
      </span>
      <div className="testi-avater">
        <img
          src="assets/img/testi/testi-auth-3-1.png"
          alt="Testimonial Author"
        />
      </div>
      <div className="testi-content">
        <div className="testi-rating">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
        </div>
        <p className="testi-text">
        Best place for buying wholesale and retail dehydrated food products. I have purchased most of their items, everything is too good in quality and price.
        </p>
        <h3 className="testi-name">Mythili K</h3>
        
      </div>
    </div>
  </div>
  </Carousel.Item> 

  <Carousel.Item>
  <div className="col-lg-12">
    <div className="testi-style3">
      <span className="testi-icon">
        <i className="fas fa-quote-right" />
      </span>
      <div className="testi-avater">
        <img
          src="assets/img/testi/testi-auth-3-1.png"
          alt="Testimonial Author"
        />
      </div>
      <div className="testi-content">
        <div className="testi-rating">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
        </div>
        <p className="testi-text">
        Overall our experience with Aayush foods providing High quality products and always they are maintaining costomer relationship it's very impressed.
        </p>
        <h3 className="testi-name">SAF Trading Co.</h3>
        
      </div>
    </div>
  </div>
  </Carousel.Item> 

  <Carousel.Item>
  <div className="col-lg-12">
    <div className="testi-style3">
      <span className="testi-icon">
        <i className="fas fa-quote-right" />
      </span>
      <div className="testi-avater">
        <img
          src="assets/img/testi/testi-auth-3-1.png"
          alt="Testimonial Author"
        />
      </div>
      <div className="testi-content">
        <div className="testi-rating">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
        </div>
        <p className="testi-text">
          The customer service was outstanding and the quality of the product was what exactly I was looking for. As a beginner in this industry they gave more suggestions for my new projects as well.
        </p>
        <h3 className="testi-name">Dhejasvee Rajagopal</h3>
        
      </div>
    </div>
  </div>
  </Carousel.Item> 

  <Carousel.Item>
  <div className="col-lg-12">
    <div className="testi-style3">
      <span className="testi-icon">
        <i className="fas fa-quote-right" />
      </span>
      <div className="testi-avater">
        <img
          src="assets/img/testi/testi-auth-3-1.png"
          alt="Testimonial Author"
        />
      </div>
      <div className="testi-content">
        <div className="testi-rating">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
        </div>
        <p className="testi-text">
        First of all congrats to Aayush food products. All the spices manufactured here are really excellent.I have tried most of the products.All the products are spicy and made with health care conscious.
        </p>
        <h3 className="testi-name">Thanvik Visakan</h3>
        
      </div>
    </div>
  </div>
  </Carousel.Item>

  <Carousel.Item>
  <div className="col-lg-12">
    <div className="testi-style3">
      <span className="testi-icon">
        <i className="fas fa-quote-right" />
      </span>
      <div className="testi-avater">
        <img
          src="assets/img/testi/testi-auth-3-1.png"
          alt="Testimonial Author"
        />
      </div>
      <div className="testi-content">
        <div className="testi-rating">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
        </div>
        <p className="testi-text">
        The staff was incredibly welcoming and helpful. I had a wonderful experience and I highly recommend this company to others. Wish you All the best and sucess for your future ventures.
        </p>
        <h3 className="testi-name">PRSYSTEMS45 SYSTEMS</h3>
        
      </div>
    </div>
  </div>
  </Carousel.Item>
  </Carousel>
    </div>
    </div>
</section>
</>
  )
}

export default HomeTestimonial