import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import PhotoGallery from './pages/PhotoGallery';
import Products from './pages/Products';
import Quote from './pages/Quote';
import DehydratedVegetables from './pages/dehydrated/DehydratedVegetables';
  import GarlicFlakes from './pages/dehydrated/vegetablepowders/GarlicFlakes';
  import RedonionFlakes from './pages/dehydrated/vegetablepowders/RedonionFlakes';
  import WhiteonionFlakes from './pages/dehydrated/vegetablepowders/WhiteonionFlakes';
  import CarrotFlakes from './pages/dehydrated/vegetablepowders/CarrotFlakes';
  import PotatoFlakes from './pages/dehydrated/vegetablepowders/PotatoFlakes';
  import AmachurFlakes from './pages/dehydrated/vegetablepowders/AmachurFlakes';
  import CabbageFlakes from './pages/dehydrated/vegetablepowders/CabbageFlakes';
  import ChilliFlakes from './pages/dehydrated/vegetablepowders/ChilliFlakes';
  import TomatoFlakes from './pages/dehydrated/vegetablepowders/TomatoFlakes';
  import CauliFlower from './pages/dehydrated/vegetablepowders/CauliFlower';
  import OkraFlakes from './pages/dehydrated/vegetablepowders/OkraFlakes';
  import CucumberSlices from './pages/dehydrated/vegetablepowders/CucumberSlices';
  import GreenPeas from './pages/dehydrated/vegetablepowders/GreenPeas';
  import BeetrootSlices from './pages/dehydrated/vegetablepowders/BeetrootSlices';
  import BittergourdSlices from './pages/dehydrated/vegetablepowders/BittergourdSlices';
  import FrenchBeans from './pages/dehydrated/vegetablepowders/FrenchBeans';
  import DrumStick from './pages/dehydrated/vegetablepowders/DrumStick';
  import GreenCapsicum from './pages/dehydrated/vegetablepowders/GreenCapsicum';
  import SmallOnion from './pages/dehydrated/vegetablepowders/SmallOnion';
  import RedCapsicum from './pages/dehydrated/vegetablepowders/RedCapsicum';
import VegetablePowders from './pages/dehydrated/vegetablepowders/VegetablePowders';
import SpraydriedVegetablepowder from './pages/dehydrated/vegetablepowders/SpraydriedVegetablepowder';
  import GuvaPowder from './pages/dehydrated/fruitspowder/GuvaPowder';
  import Apple from './pages/dehydrated/fruitspowder/Apple';
  import Banana from './pages/dehydrated/fruitspowder/Banana';
  import Lemon from './pages/dehydrated/fruitspowder/Lemon';
  import Amla from './pages/dehydrated/fruitspowder/Amla';
  import Papaya from './pages/dehydrated/fruitspowder/Papaya';
  import UnripePapaya from './pages/dehydrated/fruitspowder/UnripePapaya';
  import Orange from './pages/dehydrated/fruitspowder/Orange';
  import Pomegranate from './pages/dehydrated/fruitspowder/Pomegranate';
  import Mango from './pages/dehydrated/fruitspowder/Mango';
  import Tamarind from './pages/dehydrated/fruitspowder/Tamarind';
  import Litchi from './pages/dehydrated/fruitspowder/Litchi';
  import Kiwi from './pages/dehydrated/fruitspowder/Kiwi';
  import Avacado from './pages/dehydrated/fruitspowder/Avacado';
  import Pineapple from './pages/dehydrated/fruitspowder/Pineapple';
  import Melon from './pages/dehydrated/fruitspowder/Melon';
  import Peach from './pages/dehydrated/fruitspowder/Peach';
  import BlackGrapes from './pages/dehydrated/fruitspowder/BlackGrapes';
  import Chikoo from './pages/dehydrated/fruitspowder/Chikoo';
  import Fig from './pages/dehydrated/fruitspowder/Fig';
  import Vanilla from './pages/dehydrated/fruitspowder/Vanilla';
  import Cocoa from './pages/dehydrated/fruitspowder/Cocoa';
  import Dates from './pages/dehydrated/fruitspowder/Dates';
  import CheddarCheese from './pages/dehydrated/fruitspowder/CheddarCheese';
  import Caramel from './pages/dehydrated/fruitspowder/Caramel';
  import Curd from './pages/dehydrated/fruitspowder/Curd';
  import Yogurt from './pages/dehydrated/fruitspowder/Yogurt';
  import TenderCoconut from './pages/dehydrated/fruitspowder/TenderCoconut';
  import Honey from './pages/dehydrated/fruitspowder/Honey';
import DehydratedfruitsPowder from './pages/dehydrated/fruitspowder/DehydratedfruitsPowder';
import SpraydriedFruitspowder from './pages/dehydrated/fruitspowder/SpraydriedFruitspowder';
import DriedLeavesPowder from './pages/dehydrated/leafpowders/DriedLeavesPowder';
  import Mint from './pages/dehydrated/leafpowders/Mint';
  import Coriander from './pages/dehydrated/leafpowders/Coriander';
  import Fenugreek from './pages/dehydrated/leafpowders/Fenugreek';
  import Spinach from './pages/dehydrated/leafpowders/Spinach';
  import Bay from './pages/dehydrated/leafpowders/Bay';
  import Moringa from './pages/dehydrated/leafpowders/Moringa';
  import CurryLeaves from './pages/dehydrated/leafpowders/CurryLeaves';
  import Spearmint from './pages/dehydrated/leafpowders/Spearmint';
  import Peppermint from './pages/dehydrated/leafpowders/Peppermint';
  import Rambha from './pages/dehydrated/leafpowders/Rambha';
import SpecialLeaves from './pages/dehydrated/specialityproducts/SpecialLeaves';
import SpicePowders from './pages/spices/SpicePowders';
  import RedChilli from './pages/spices/RedChilli';
  import MarathiMoggu from './pages/spices/MarathiMoggu';
  import BlackPepper from './pages/spices/BlackPepper';
  import TailedPepper from './pages/spices/TailedPepper';
  import WhitePepper from './pages/spices/WhitePepper';
  import LongPepper from './pages/spices/LongPepper';
  import Clove from './pages/spices/Clove';
  import Cardmom from './pages/spices/Cardmom';
  import Nutmeg from './pages/spices/Nutmeg';
  import StarAnise from './pages/spices/StarAnise';
  import Ginger from './pages/spices/Ginger';
  import RedPaprica from './pages/spices/RedPaprica';
  import Sichuan from './pages/spices/Sichuan';
  import Poppy from './pages/spices/Poppy';
  import BirdEye from './pages/spices/BirdEye';
  import Ajwain from './pages/spices/Ajwain';
  import Bhutjolokia from './pages/spices/BhutJolokia';
import TeabagCut from './pages/herbs/TeabagCut';
  import OrangePeel from './pages/herbs/tbc/OrangePeel';
  import LemonPeel from './pages/herbs/tbc/LemonPeel';
  import LemonGrass from './pages/herbs/tbc/LemonGrass';
  import AshwagandhaRoot from './pages/herbs/tbc/AswagandhaRoot';
  import Cinamom from './pages/herbs/tbc/Cinamom';
  import BlackPepperTBC from './pages/herbs/tbc/BlackPepperTBC';
  import CardamomTBC from './pages/herbs/tbc/CardamomTBC';
  import GingerTBC from './pages/herbs/tbc/GingerTBC';
  import TurmericTbc from './pages/herbs/tbc/TurmericTbc';
  import Fennel from './pages/herbs/tbc/Fennel';
  import RosePetals from './pages/herbs/tbc/RosePetals';
  import RasberryTbc from './pages/herbs/tbc/RasberryTbc';
  import MintLeavesTbc from './pages/herbs/tbc/MintLeavesTbc';
  import Stevia from './pages/herbs/tbc/Stevia';
  import Hibiscus from './pages/herbs/tbc/Hibiscus';
  import MoringaLeavesTbc from './pages/herbs/tbc/MoringaLeavesTbc';
  import TulsiTbc from './pages/herbs/tbc/TulsiTbc';
  import CurryLeavesTbc from './pages/herbs/tbc/CurryLeavesTbc';
  import SpearmintLeavesTbc from './pages/herbs/tbc/SpearmintLeavesTbc';
  import PeppermintLeavesTbc from './pages/herbs/tbc/PeppermintLeavesTbc';
  import NutmegTbc from './pages/herbs/tbc/NutmegTbc';
  import AshwagandhaTbc from './pages/herbs/tbc/AshwagandhaTbc';
  import Licorice from './pages/herbs/tbc/Licorice';
  import BayLeaves from './pages/herbs/tbc/BayLeaves';
  import Rosemarry from './pages/herbs/tbc/Rosemarry';
  import Sage from './pages/herbs/tbc/Sage';
  import Rosehips from './pages/herbs/tbc/Rosehips';
import SeasoningHerbs from './pages/herbs/SeasoningHerbs';
import DriedFlowers from './pages/flower/DriedFlowers';
import SpecialityProducts from './pages/dehydrated/specialityproducts/SpecialityProducts';
import HayakuFlavours from './pages/HayakuFlavours';
import LeavesHerbs from './pages/herbs/LeavesHerbs';
import Application from './pages/Application';
import Top from './components/Top';
import Header from './components/Header';
import Footer from './components/Footer';

import { BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";

//import SpraydriedFruitspowder from './pages/dehydrated/fruitspowder/SpraydriedFruitspowder';

function App() {
  return (
    <Router>
    <Top/>
    <Header/>    
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<PhotoGallery />} />
        <Route path="/dehydrated-vegetables-powders" element={<DehydratedVegetables />} />
          <Route path="/garlic-flakes" element={<GarlicFlakes />} />
          <Route path="/red-onion-flakes" element={<RedonionFlakes />} />
          <Route path="/white-onion-flakes" element={<WhiteonionFlakes />} />
          <Route path="/carrot-flakes" element={<CarrotFlakes />} />
          <Route path="/potato-flakes" element={<PotatoFlakes />} />
          <Route path="/amachur-flakes" element={<AmachurFlakes />} />
          <Route path="/cabbage-flakes" element={<CabbageFlakes />} />
          <Route path="/green-chilli-flakes" element={<ChilliFlakes />} />
          <Route path="/tomato-flakes" element={<TomatoFlakes />} />
          <Route path="/amachur-flakes" element={<AmachurFlakes />} />
          <Route path="/french-beans-flakes" element={<FrenchBeans />} />
          <Route path="/red-capsicum-flakes" element={<RedCapsicum />} />
          <Route path="/green-capsicum-flakes" element={<GreenCapsicum />} />
          <Route path="/bittergourd-slices" element={<BittergourdSlices />} />
          <Route path="/drumstick-flakes" element={<DrumStick />} />
          <Route path="/small-onion-flakes" element={<SmallOnion/>} />
          <Route path="/cauliflower-flakes" element={<CauliFlower />} />
          <Route path="/okra-flakes" element={<OkraFlakes />} />
          <Route path="/cucumber-slices" element={<CucumberSlices />} />
          <Route path="/green-peas-whole" element={<GreenPeas />} />
          <Route path="/beetroot_slices" element={<BeetrootSlices />} />
        <Route path="/vegetable-powders" element={<VegetablePowders />} />
        <Route path="/spray-dried-vegetable-powders" element={<SpraydriedVegetablepowder />} />
          <Route path="/guava-powder" element={<GuvaPowder />} />
          <Route path="/apple-powder" element={<Apple />} />
          <Route path="/banana-powder" element={<Banana />} />
          <Route path="/lemon-powder" element={<Lemon />} />
          <Route path="/amla-powder" element={<Amla />} />
          <Route path="/papaya-powder" element={<Papaya />} />
          <Route path="/unripe-papaya-powder" element={<UnripePapaya />} />
          <Route path="/orange-powder" element={<Orange />} />
          <Route path="/pomegranate-powder" element={<Pomegranate />} />
          <Route path="/mango-powder-alponso" element={<Mango />} />
          <Route path="/tamarind-powder" element={<Tamarind />} />
          <Route path="/litchi-powder" element={<Litchi />} />
          <Route path="/kiwi-powder" element={<Kiwi />} />
          <Route path="/avacado-powder" element={<Avacado />} />
          <Route path="/pineapple-powder" element={<Pineapple />} />
          <Route path="/melon-powder" element={<Melon />} />
          <Route path="/peach-powder" element={<Peach />} />
          <Route path="/black-grapes-powder" element={<BlackGrapes />} />
          <Route path="/chikoo-powder" element={<Chikoo />} />
          <Route path="/fig-powder" element={<Fig />} />
          <Route path="/vanilla-powder" element={<Vanilla />} />
          <Route path="/cocoa-powder" element={<Cocoa />} />
          <Route path="/dates-powder" element={<Dates />} />
          <Route path="/cheddar-cheese-powder" element={<CheddarCheese />} />
          <Route path="/caramel-powder" element={<Caramel />} />
          <Route path="/honey-powder" element={<Honey />} />
          <Route path="/curd-powder" element={<Curd />} />
          <Route path="/yogurt-powder" element={<Yogurt />} />
          <Route path="/tender-coconut-powder" element={<TenderCoconut />} />
        <Route path="/dehydrated-fruits-powder" element={<DehydratedfruitsPowder />} />
        <Route path="/spray-dried-fruits-powder" element={<SpraydriedFruitspowder />} />
        <Route path="/dried-leaves-powder" element={<DriedLeavesPowder />} />
        <Route path="/speciality-leaves" element={<SpecialLeaves />} />
        <Route path="/raw-spices-and-powders" element={<SpicePowders />} />
          <Route path="/red-chilli-flakes" element={<RedChilli />} />
          <Route path="/marathi-moggu" element={<MarathiMoggu />} />
          <Route path="/black-pepper-whole" element={<BlackPepper />} />
          <Route path="/white-pepper-whole" element={<WhitePepper />} />
          <Route path="/tail-pepper" element={<TailedPepper />} />
          <Route path="/long-pepper" element={<LongPepper />} />
          <Route path="/clove-whole" element={<Clove />} />
          <Route path="/cardmom-whole" element={<Cardmom />} />
          <Route path="/nutmeg-whole" element={<Nutmeg />} />
          <Route path="/star-anise" element={<StarAnise />} />
          <Route path="/ginger-whole" element={<Ginger />} />
          <Route path="/red-paprika-powder" element={<RedPaprica />} />
          <Route path="/sichuan-pepper" element={<Sichuan />} />
          <Route path="/poppy-seeds" element={<Poppy />} />
          <Route path="/bird-eye-chilli-whole" element={<BirdEye />} />
          <Route path="/ajwain-seeds" element={<Ajwain />} />
          <Route path="/bhut-jolokia-ghost-pepper" element={<Bhutjolokia />} />
        <Route path="/tea-bag-cut" element={<TeabagCut />} />
          <Route path="/orange-peel" element={<OrangePeel />} />
          <Route path="/lemon-peel" element={<LemonPeel />} />
          <Route path="/lemon-grass" element={<LemonGrass />} />
          <Route path="/ashwagandha-root" element={<AshwagandhaRoot />} />
          <Route path="/cinnamon-tbc" element={<Cinamom />} />
          <Route path="/black-pepper-tbc" element={<BlackPepperTBC />} />
          <Route path="/cardamom-tbc" element={<CardamomTBC />} />
          <Route path="/turmeric-tbc" element={<TurmericTbc />} />
          <Route path="/fennel-tbc" element={<Fennel />} />
          <Route path="/raspberry-leaves" element={<RasberryTbc />} />
          <Route path="/rose-petals" element={<RosePetals />} />
          <Route path="/ginger-tbc" element={<GingerTBC />} />
          <Route path="/mint-leaves-tbc" element={<MintLeavesTbc />} />
          <Route path="/hibiscus-flower-tbc" element={<Hibiscus />} />
          <Route path="/stevia" element={<Stevia />} />
          <Route path="/moringa-leaves" element={<MoringaLeavesTbc />} />
          <Route path="/tulsi" element={<TulsiTbc />} />
          <Route path="/curry-leaves-tbc" element={<CurryLeavesTbc />} />
          <Route path="/spearmint-leaves-tbc" element={<SpearmintLeavesTbc />} />
          <Route path="/peppermint-leaves-tbc" element={<PeppermintLeavesTbc />} />
          <Route path="/nutmeg-tbc" element={<NutmegTbc />} />
          <Route path="/ashwagandha-tbc" element={<AshwagandhaTbc />} />
          <Route path="/licorice" element={<Licorice />} />
          <Route path="/bay-leaves-tbc" element={<BayLeaves />} />
          <Route path="/rosemary-imported" element={<Rosemarry />} />
          <Route path="/sage" element={<Sage />} />
          <Route path="/rosehips" element={<Rosehips />} />

        <Route path="/seasoning-herbs" element={<SeasoningHerbs />} />
        <Route path="/dried-flowers-and-powders" element={<DriedFlowers />} />
        <Route path="/speciality-products" element={<SpecialityProducts />} />
        <Route path="/retail-brand-hayaku-flavours" element={<HayakuFlavours />} />
        <Route path="/dried-leaves-and-herbs" element={<LeavesHerbs />} />
          <Route path="/mint-leaves-powder" element={<Mint />} />
          <Route path="/coriander-leaves-powder" element={<Coriander />} />
          <Route path="/rambha-pandan-leaves" element={<Rambha />} />
          <Route path="/peppermint" element={<Peppermint />} />
          <Route path="/spearmint-leaves-powder" element={<Spearmint />} />
          <Route path="/curry-leaves-powder" element={<CurryLeaves />} />
          <Route path="/moringa-leaves-powder" element={<Moringa />} />
          <Route path="/bay-leaves-powder" element={<Bay />} />
          <Route path="/spinach-leaves-powder" element={<Spinach />} />
          <Route path="/methi-leaves-powder" element={<Fenugreek />} />
        <Route path="/aayush-products" element={<Products />} />
        <Route path="/get-quote" element={<Quote />} />
        <Route path="/application" element={<Application />} />
      </Routes>
    <Footer />
    </Router>
    
  );
}

export default App;
