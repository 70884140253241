import React from 'react';


function HomeGallery() {
  const [index, setIndex] = React.useState(-1);
  return (
    <section className="space-bottom">
  <div
    className="shape-mockup jump d-none d-xxl-block" data-left="1%"  data-top="-5%" >
    <img src="assets/img/shape/flower-1-2.png" alt="flower" />
  </div>
  <div className="container">
    <div className="row justify-content-center text-center">
      <div
        className="col-md-9 col-lg-7 col-xl-6 wow fadeInUp wow-animated"  data-wow-delay="0.2s" >
        <div className="title-area">
          <h2 className="sec-title4">Fine Quality of Aayush Products</h2>
        </div>
      </div>
    </div>
    <div
      className="row filter-active gx-4 gy-gx wow fadeInUp"  data-wow-delay="0.3s" >
      <div className="col-sm-6 col-lg-4 col-xxl-auto filter-item">
        <div className="gallery-style3 image-scale-hover">
          <a
            href="assets/img/gallery/gal-5-1.jpg"  className="icon-btn popup-image" >
            <i className="far fa-eye" />
          </a>
          <img src="assets/img/gallery/gal-5-1.jpg" alt="Gallery Image"   className="w-100" />
        </div>
      </div>
      <div className="col-sm-6 col-lg-4 col-xxl-auto filter-item">
        <div className="gallery-style3 image-scale-hover">
          <a href="assets/img/gallery/gal-5-2.jpg"  className="icon-btn popup-image"  >
            <i className="far fa-eye" />
          </a>
          <img src="assets/img/gallery/gal-5-2.jpg"  alt="Gallery Image"  className="w-100" />
        </div>
      </div>
      <div className="col-sm-6 col-lg-4 col-xxl-auto filter-item">
        <div className="gallery-style3 image-scale-hover">
          <a
            href="assets/img/gallery/gal-5-3.jpg" className="icon-btn popup-image" >
            <i className="far fa-eye" />
          </a>
          <img  src="assets/img/gallery/gal-5-3.jpg"  alt="Gallery Image" className="w-100" />
        </div>
      </div>
      <div className="col-sm-6 col-lg-4 col-xxl-auto filter-item">
        <div className="gallery-style3 image-scale-hover">
          <a  href="assets/img/gallery/gal-5-4.jpg" className="icon-btn popup-image"  >
            <i className="far fa-eye" />
          </a>
          <img  src="assets/img/gallery/gal-5-4.jpg" alt="Gallery Image" className="w-100" />
        </div>
      </div>
      <div className="col-sm-6 col-lg-4 col-xxl-auto filter-item">
        <div className="gallery-style3 image-scale-hover">
          <a  href="assets/img/gallery/gal-5-1.jpg" className="icon-btn popup-image" >
            <i className="far fa-eye" />
          </a>
          <img  src="assets/img/gallery/gal-5-1.jpg" alt="Gallery Image"  className="w-100"/>
        </div>
      </div>
      <div className="col-sm-6 col-lg-4 col-xxl-auto filter-item">
        <div className="gallery-style3 image-scale-hover">
          <a  href="assets/img/gallery/gal-5-3.jpg" className="icon-btn popup-image"  >
            <i className="far fa-eye" />
          </a>
          <img  src="assets/img/gallery/gal-5-3.jpg" alt="Gallery Image"  className="w-100" />
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default HomeGallery