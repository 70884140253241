import React from "react";

class Application extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="breadcumb-wrapper "
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/contactus.jpg)`,
                        backgroundSize: 'cover',
                    }}
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Application 
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        Application
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section className=" space">
  <div className="service-inner1">
    <div
      className="shape-mockup jump d-none d-xxl-block"
      data-top="-10%"
      data-right={0}
    >
      <img src="assets/img/hero/hero-leaf-5.png" alt="shape" />
    </div>
    <div className="container-xl">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-6 col-lg-5 col-xxl-auto">
          <div className="service-style1 reverse">
            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Spice & Masala Industry 
                </a>
              </h3>
              <p className="service-text">
              Manufacturing and distribution of a wide range of spices and masalas for culinary purposes, offering diverse flavors and aromas to enhance dishes.
              </p>
            </div>
          </div>
          <div className="service-style1 reverse">
            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Pickle Industry 
                </a>
              </h3>
              <p className="service-text">
              Production of preserved vegetables or fruits in various brines or oils, providing tangy and savory condiments for meals.
              </p>
            </div>
          </div>
          <div className="service-style1 reverse">
            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Noodles Industry
                </a>
              </h3>
              <p className="service-text">
              Manufacturing of quick-cooking noodles made from wheat, rice, or other grains, offering convenience and versatility in meal preparation.
              </p>
            </div>
          </div>
          <div className="service-style1 reverse">            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Oleoresins
                </a>
              </h3>
              <p className="service-text">
              Concentrated extracts from spices or herbs, containing essential oils and other aromatic compounds, used as flavoring agents in food and beverage products.
              </p>
            </div>
          </div>
          <div className="service-style1 reverse">            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Tea Ingredients (TBC Ingredients)
                </a>
              </h3>
              <p className="service-text">
              Various components used in the production of tea, including tea leaves, herbs, fruits, and flavorings, to create diverse blends and flavors.
              </p>
            </div>
          </div>
          <div className="service-style1 reverse">            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Pharmaceutical Ingredients
                </a>
              </h3>
              <p className="service-text">
              Active substances used in the formulation of pharmaceutical drugs, including chemicals, extracts, and synthetic compounds.
              </p>
            </div>
          </div>
        </div>


        <div className="col col-xxl-auto text-center d-none d-lg-block">
          <img
            src="assets/img/bg/sr-shape-1-4.png"
            alt="shape"
            className="mt-n4"
          />
        </div>


        <div className="col-md-6 col-lg-5 col-xxl-auto">
          <div className="service-style1">
           
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                  Ready-to-Eat 
                </a>
              </h3>
              <p className="service-text">
              Pre-packaged meals or snacks that require minimal preparation, providing convenience for consumers with busy lifestyles.
              </p>
            </div>
          </div>
          <div className="service-style1">
           
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Ready-to-Cook 
                </a>
              </h3>
              <p className="service-text">
              Pre-prepared meal kits containing ingredients and instructions for easy and quick cooking at home, offering a convenient alternative to meal planning and grocery shopping.
              </p>
            </div>
          </div>
          <div className="service-style1">
            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Herbal Oil Extraction Industry 
                </a>
              </h3>
              <p className="service-text">
              Extraction and processing of natural oils from herbs and plants for use in cooking, aromatherapy, and cosmetic products.
              </p>
            </div>
          </div>
          <div className="service-style1">
           
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Healthy Mix Industry
                </a>
              </h3>
              <p className="service-text">
              Production of nutrient-rich blends of grains, nuts, seeds, and dried fruits, offering convenient and wholesome snack options.
              </p>
            </div>
          </div>
          <div className="service-style1">
           
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Seasoning Industry  
                </a>
              </h3>
              <p className="service-text">
              Manufacturing and distribution of a variety of seasonings, including salt, pepper, herbs, and spices, to enhance the flavor of foods.
              </p>
            </div>
          </div>
          <div className="service-style1">
            
            <div className="service-content">
              <h3 className="service-title">
                <a href="#" className="text-inherit">
                Instant Foods  
                </a>
              </h3>
              <p className="service-text">
              Quick-preparation food products such as instant noodles, soups, and beverages, providing convenience and ease of consumption.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </div>
        )
    }
}

export default Application;