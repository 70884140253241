import React from 'react';

function HomeAbout() {
  return (
    <section className="space">
  <div className="container">
    <div className="row gx-50 flex-row-reverse">
      <div className="col-lg-6 mb-40 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
        <div className="img-box6">
          <div className="img-1">
            <img src="assets/img/about/ab-5-1.jpg" alt="thumb" />
          </div>
          {/*<div class="img-2 ani-moving-x"><img src="assets/img/shape/leaf-1-9.png" alt="leaf"></div>*/}
        </div>
      </div>
      <div
        className="col-lg-6 align-self-center wow fadeInUp"
        data-wow-delay="0.3s"
      >
        <span className="sec-subtitle5">
          Your <span className="inner-text">Best Price</span> is Right Here
        </span>
        <h2 className="sec-title4">
          Why Choose <span className="inner-text"> Us</span>
        </h2>
        <p className="sec-text2">
        Aayush Food Products is a leading manufacturer & exporter of Dehydrated Vegetables and spray dried fruit powder (Natural Fruit Powder), Natural dried leaves, Raw Spices, Spice Powders and Herbs of finest quality matching the international 10 food safety standards.
        </p>
        <div className="media-style4">
          <div className="media-img">
            <a href="/about"><img src="assets/img/about/ab-5-2.jpg" alt="organic" /></a>
          </div>
          <div className="media-body">
            <p className="media-text">
              We, Aayush Food Products delivers a comprehensive
              portfolio of ingredients and formulation expertise to a broad
              range of applications.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-sm-auto">
            <div className="media-style5">
              <span className="media-icon">
                <i className="fal fa-users" />
              </span>
              <span className="media-number">400</span>
              <p className="media-text">Organic products</p>
            </div>
          </div>
          <div className="col-6 col-sm-auto">
            <div className="media-style5 bg-theme">
              <span className="media-icon">
                <i className="fal fa-globe-americas" />
              </span>
              <span className="media-number">2000</span>
              <p className="media-text">Happy clients</p>
            </div>
          </div>
          
        </div>
        <a href="/about" className="vs-btn" style = {{  marginTop:30,marginLeft : 370 }}>
              Read More
            </a>
      </div>
    </div>
  </div>
</section>

  )
}

export default HomeAbout