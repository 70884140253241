import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class OrangePeel extends React.Component {
    render() {
        return (
            <div>
               <Helmet>
                    <title>Orange Peel - Tea Bag Cuts (TBC) | Dehydrated vegetables Flakes | Spray dried fruits powder | Dried leaves and herbs | Raw Spice and Powders </title>
                </Helmet>

                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Red_chilli_flakes.png" alt="Orange Peel" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Red_chilli_flakes1.png" alt="Orange Peel" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Orange Peel</h2>

                                    <p className="product-text">
                                    Dive into the sun-soaked allure of Aayush Orange Peel Tea Bags, where every sip transports you to the heart of lush orange groves. Our tea bags are meticulously crafted to capture the essence of freshly harvested oranges, infusing your senses with the invigorating aroma and tangy-sweet flavor that nature intended. Each sip is a celebration of pure indulgence, a symphony of citrus notes that dance on your palate, leaving you refreshed and rejuvenated.
                                    </p>
                                    
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="/tea-bag-cut">Tea Bag Cut (TBC)</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    Brewing Instructions:
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Features & Benefits:</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Pure Essence:</b> Handpicked from the finest orchards, our orange peels promise unparalleled freshness and quality.</li>
                                            <li><b>Natural Flavor:  </b>Experience the burst of sunshine in every cup, with the authentic taste of ripe, juicy oranges.</li>
                                            <li><b>Antioxidant Powerhouse:  </b>Fortify your body with a potent dose of antioxidants, nurturing your immune system and promoting overall well-being.</li>
                                            <li><b>Caffeine-Free:  </b>Embrace the tranquility of caffeine-free bliss, perfect for unwinding after a long day or enjoying a peaceful moment anytime.</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    <h4>Brewing Instructions:</h4>
                                                    </p>
                                                    <div className="vs-post-comment">                                                        
                                                        <div className="comment-content">
                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>1. Boil fresh, filtered water and pour it into your favorite cup or mug.</p>
                                                                    <p>2. Drop one Aayush Orange Peel Tea Bag into the water, allowing it to gently infuse.</p>
                                                                    <p>3. Steep for 3-5 minutes to unlock the full spectrum of flavors and benefits.</p>
                                                                    <p>4. Remove the tea bag, take a deep breath, and savor the citrus-infused bliss.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/lemon-peel">
                                            <img
                                                src="assets/img/product/thumb/lemon_peel.png"
                                                alt="lemon-peel"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/lemon-peel">
                                                Lemon peel
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/lemon-grass">
                                            <img
                                                src="assets/img/product/thumb/lemon_grass.png"
                                                alt="lemon-grass"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/lemon-grass">
                                                Lemon Grass
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/ginger-tbc">
                                            <img
                                                src="assets/img/product/thumb/ginger_tbc.png"
                                                alt="ginger-tbc"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/ginger-tbc">
                                                Ginger
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/cardamom-tbc">
                                            <img
                                                src="assets/img/product/thumb/cardamom_tbc.png"
                                                alt="cardamom-tbc"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/cardamom-tbc">
                                                Cardamom
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default OrangePeel;