import React from "react";

class DriedLeavesPowder extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="breadcumb-wrapper" style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/herbal.jpg)`,
                        backgroundSize: 'cover',
                    }}
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Dried Leaves <span className="inner-text"> & Powders</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li>
                                        Our <span className="inner-text">Driede Leaves and Powder</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> In every leaf, there's a story of nature's bounty waiting to be discovered.</span>
                                                <h2 className="sec-title4"> Embrace Nature's Essence with Aayush Food Products</h2>
                                                <p className="sec-text">At Aayush Food Products, we take pride in delivering the pure essence of nature through our range of dried leaves and powders. From the refreshing aroma of mint leaves to the rich flavor of fenugreek leaves and the vibrant essence of curry leaves, each product is carefully harvested and processed to retain its natural goodness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="mint-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/mint_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="mint-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="mint-leaves-powder">
                                                            Mint leaves / Powder
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="coriander-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/coriander_leaves.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="coriander-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="coriander-leaves-powder">
                                                            Coriander Leaves / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="methi-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/methi_leaves.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="methi-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="methi-leaves-powder">
                                                            Fenugreek / Methi Leaves  / Powder
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="spinach-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/spinach_leaves.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="spinach-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="spinach-leaves-powder">
                                                            Spinach Leaves / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="bay-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/bay_leaves_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="bay-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                      
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="bay-leaves-powder">
                                                            Bay leaves / Powder
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="moringa-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/moringa_leaves_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="moringa-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="moringa-leaves-powder">
                                                            Moringa leaves / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="curry-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/curry_leaves_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="curry-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="curry-leaves-powder">
                                                            Curry Leaves  / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="spearmint-leaves-powder">
                                                        <img
                                                            src="assets/img/product/thumb/spearmint.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="spearmint-leaves-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                      
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="spearmint-leaves-powder">
                                                            Spearmint
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="peppermint">
                                                        <img
                                                            src="assets/img/product/thumb/peppermint.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="peppermint" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="peppermint">
                                                            Peppermint
                                                            </a>
                                                        </h3>
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="rambha-pandan-leaves">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="rambha-pandan-leaves" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="rambha-pandan-leaves">
                                                            Rambha/ Pandan Leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default DriedLeavesPowder;