import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class BirdEye extends React.Component {
    render() {
        return (
            <div>
               <Helmet>
                    <title>Whole Bird's Eye Chilli - Raw spices and spice powders | Dehydrated vegetables Flakes | Spray dried fruits powder | Dried leaves and herbs </title>
                </Helmet>

                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Red_chilli_flakes.png" alt="Whole Bird's Eye Chilli" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Red_chilli_flakes1.png" alt="Whole Bird's Eye Chilli" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Whole Bird's Eye Chilli</h2>

                                    <p className="product-text">
                                    Ignite your taste buds with the fiery intensity of Bird's Eye Chilli, small yet mighty peppers known for their potent heat and vibrant color. Sourced from the Capsicum annuum plant, our premium Bird's Eye Chilli peppers are carefully selected to ensure peak ripeness and maximum spiciness. Whether in vivid red or vibrant green hues, each tiny pepper packs a punch, infusing your dishes with an electrifying burst of heat and flavor.
                                    </p>
                                    
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="/raw-spices-and-powders">Raw Spice and spice powders</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Why Choose Our Bird's Eye Chilli from Aayush Food Products?</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Superior Quality:</b> We take pride in offering only the highest quality Bird's Eye Chilli peppers, sourced from trusted growers who uphold rigorous standards of cultivation and harvesting. Our peppers are handpicked at the perfect stage of ripeness to ensure optimal flavor and heat, delivering a premium product that excites the senses.</li>
                                            <li><b>Intense Heat:  </b>Our Bird's Eye Chilli peppers are renowned for their intense heat, making them a favorite among spice enthusiasts and adventurous cooks. Whether you prefer the fiery kick of red chillies or the sharp bite of green ones, our peppers add depth and complexity to your dishes, elevating them to new levels of spiciness.</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    <h4>Embrace the Fiery Flavor of Bird's Eye Chilli: A Spice of Intense Heat</h4>
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Spicing Up Curries:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>Add whole Bird's Eye Chilli peppers to your favorite curry recipes for a fiery kick that enhances the flavor of the dish. Alternatively, slice or mince them for a more intense heat.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Garnishing and Decoration: </h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                   <p> Use whole or sliced Bird's Eye Chilli peppers as a garnish to add color and heat to soups, salads, and appetizers, or use them to decorate plates for a visually stunning presentation.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/green-chilli-flakes">
                                            <img
                                                src="assets/img/product/thumb/green_chilli_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/green-chilli-flakes">
                                                Green Chilli Flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default BirdEye;