import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class StarAnise extends React.Component {
    render() {
        return (
            <div>
               <Helmet>
                    <title>Star Anise Whole | Dehydrated vegetables Flakes | Spray dried fruits powder | Dried leaves and herbs | Raw Spices and Spice Powders</title>
                </Helmet>

                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/star_anise.png" alt="Star Anise Whole" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/star_anise.png" alt="Star Anise Whole" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Star Anise Whole</h2>

                                    <p className="product-text">
                                    Embark on a culinary journey with star anise, a spice renowned for its distinctive shape and mesmerizing fragrance. Sourced from the Illicium verum tree, our premium star anise pods are carefully selected to ensure superior quality and freshness. Each star-shaped pod exudes a complex aroma reminiscent of licorice, cloves, and warm spices, infusing your dishes with an enchanting depth of flavor.
                                    </p>
                                    
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="/raw-spices-and-powders">Raw Spice and spice powders</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Why Choose Star Anise from Aayush Food Products?</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Quality Assurance:</b> We take pride in offering only the highest quality star anise, sourced from trusted growers who adhere to strict standards of cultivation and harvesting. Each pod is handpicked at peak ripeness to ensure optimal flavor and potency, so you can enjoy the true essence of this extraordinary spice.</li>
                                            <li><b>Exquisite Aroma:  </b>Our star anise pods boast an unparalleled aroma that transports you to exotic lands with every whiff. Whether used whole or ground, their fragrant essence adds a touch of magic to your culinary creations, elevating them to new heights of deliciousness.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    <h4>Unveil the Exotic Flavors of Star Anise: A Spice of Enchanting Aroma</h4>
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Flavoring Savory Dishes:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>Use whole star anise pods to flavor savory dishes such as braised meats, curries, and soups for a rich and aromatic depth of flavor that enhances the overall taste profile.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Creating Homemade Spice Blends:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                   <p>Grind whole star anise pods into spice blends such as Chinese five-spice powder or garam masala for a homemade touch that elevates your cooking to new heights.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/green-chilli-flakes">
                                            <img
                                                src="assets/img/product/thumb/green_chilli_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/green-chilli-flakes">
                                                Green Chilli Flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default StarAnise;