import React from 'react';
import Carousel from 'react-grid-carousel';
import ModalForm from '../../../components/ModalForm';

class GarlicFlakes extends React.Component {
    
    handleShowModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }
    render() {
        return (
            <div>
                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div
                                    className="product-big-img"   >
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/garlic_flakes.png" alt="Garlic Flakes" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/garlic_flakes1.png" alt="Garlic Flakes" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Garlic Flakes</h2>

                                    <p className="product-text">
                                        We manufacture and supply Dehydrated Garlic Flakes to our clients. These garlic flakes are widely recognized for their pungent flavor and widely used for its various culinary dishes. These flakes are used for seasoning dry soups mixes, sauces, vegetable mixes, stuffing mixes and fast foods.
                                    </p>
                                    <p className="product-text">
                                        Discover the vibrant flavor and convenient versatility of garlic flakes! Sourced from premium garlic cloves, our flakes offer a convenient way to infuse dishes with the distinctive aroma and robust taste of garlic. Whether you're seasoning meats, enhancing sauces, or adding depth to soups and stews, these dried flakes provide a hassle-free alternative to fresh garlic, with a longer shelf life and easy storage. Elevate your culinary creations with the intense flavor and aromatic richness of our high-quality garlic flakes, perfect for both home cooks and professional chefs alike.
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="/dehydrated-vegetables-powders">Dehydrated Vegetable Flakes and Powders</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                    <button onClick={this.handleShowModal} className="vs-btn">
                    Get Price Quote
                </button>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                    <p>
                                        Discover the vibrant flavor and convenient versatility of garlic flakes! Sourced from premium garlic cloves, our flakes offer a convenient way to infuse dishes with the distinctive aroma and robust taste of garlic. Whether you're seasoning meats, enhancing sauces, or adding depth to soups and stews, these dried flakes provide a hassle-free alternative to fresh garlic, with a longer shelf life and easy storage. Elevate your culinary creations with the intense flavor and aromatic richness of our high-quality garlic flakes, perfect for both home cooks and professional chefs alike
                                    </p>
                                    <div className="list-style2">
                                        <ul className="list-unstyled">
                                            <li>Garlic flakes are a versatile ingredient</li>
                                            <li>Garlic flakes are a versatile ingredient</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                        Garlic flakes are a versatile ingredient that can be used in a variety of recipes to add depth of flavor and aroma. Here are some recipes and uses for garlic flakes:
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Garlic Butter</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <li>Mix softened butter with garlic flakes, salt, and parsley.</li>
                                                                    <li>Spread on bread for garlic bread, or use as a flavorful topping for grilled meats and vegetables.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Seasoning Blend</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <li>Combine garlic flakes with onion flakes, dried herbs (such as oregano, thyme, and basil), salt, and pepper.</li>
                                                                    <li>Use as a versatile seasoning blend for roasted vegetables, grilled meats, pasta dishes, and soups.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/carrot-flakes">
                                            <img
                                                src="assets/img/product/thumb/carrot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/carrot-flakes">
                                                Carrot flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/potato-flakes">
                                            <img
                                                src="assets/img/product/thumb/potato_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/potato-flakes">
                                                Potato flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalForm handleShowModal={this.handleShowModal} />
                </section>
            
            </div>
        )
    }
}

export default GarlicFlakes;