import React from 'react';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';

class Footer extends React.Component {

  render(){
        return (            
            <footer className="footer-wrapper footer-layout1">
            <div className="footer-top">
                <div className="container">
                    <div className="row align-items-stretch">
                    
                        <div className="col-md-4 d-none d-lg-flex">
                            <div className="social-style2">
                                <a href="https://www.facebook.com/Aayushfood/" target="_blank"><i className="fab fa-facebook-f"></i></a>                               
                                <a href="https://www.instagram.com/aayushfoods/" target='_blank'><i className="fab fa-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/aayush-food-products/" target='_blank'><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://t.ly/JvIS1" target='_blank'><i className="fab fa-google"></i></a>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="vs-logo">
                                <a href="#"><img src="assets/img/logo_t.png" alt="logo" /></a>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-4">
                            <form action="#" className="form-style1">
                                <h3 className="form-title">Our newsletter</h3>
                                <div className="form-group">
                                    <input type="email" placeholder="Enter your email..." />
                                    <button className="vs-btn" type="submit">Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="widget-area">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-3">
                            <div className="widget footer-widget">
                                <h3 className="widget_title">About Aayush Foods</h3>
                                <p className="footer-info"><i className="fal fa-map-marker-alt text-theme me-2"></i> No :54-A-1/74, Athipalayam Road,<br/> Ganapathy, Coimbatore-641 006. <br/> <a href="tel: 04224377322" className="text-inherit"><i className="far fa-phone-alt text-theme me-2"></i> 0422 - 4377322</a> <br/> <a className="text-inherit" href="mailto:sales@aayushfoods.in"><i className="fal fa-envelope text-theme me-2"></i>sales@aayushfoods.in</a> <br /> <a className="text-inherit" href="mailto:info@aayushfoods.in"><i className="fal fa-envelope text-theme me-2"></i>info@aayushfoods.in</a></p>
                                <h4 className="fs-22 mb-2">Open Hours</h4>
                                <p className="footer-time">Mon to Sat  <span className="time">09:00 AM - 06:30 PM</span></p>
                            </div>
                            {/*<div className="form-group">
                                    <button className="vs-btn" type="submit" style={{color: 'black'}}>If you have any queries and concerns - Contact Us</button>
        </div>*/}
        
                        </div>
                        <div className="col-md-3">
                            <div className="widget widget_nav_menu  footer-widget">
                                <h3 className="widget_title">Important Links</h3>
                                <div className="menu-all-pages-container footer-menu">
                                    <ul className="menu">
                                        <li><a href="aayush-products">AAYUSH PRODUCTS</a></li>
                                        <li><a href="/about">ABOUT US</a></li>
                                        <li><a href="/gallery">PORTFOLIO</a></li>
                                        <li><a href="applications">Applications</a></li>
                                        <li><a href="/contact">CONTACT</a></li>                                        
                                        <li><a href="/retail-brand-hayaku-flavours">Retail Brand - Hayaku Flavours</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="widget widget_nav_menu  footer-widget">
                                <h3 className="widget_title">CATEGORIES</h3>
                                <div className="menu-all-pages-container footer-menu">
                                    <ul className="menu">
                                        <li><a href="/dehydrated-vegetables-powders">DEHYDRATED VEGETASBLES & POWDERS</a></li>
                                        <li><a href="spray-dried-fruits-powder">SPRAY DRIED FRUIT POWDERS</a></li>
                                        <li><a href="dried-leaves-powder">DRIED LEAVES AND HERBS</a></li>
                                        <li><a href="raw-spices-and-powders">RAW SPICES & SPICE POWDERS</a></li>
                                        <li><a href="dried-flowers-and-powders">DRIED FLOWERS & POWDERS</a></li>
                                        <li><a href="tea-bag-cut">TBC- TEA BAG CUT</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="widget widget_nav_menu  footer-widget">
                                <h3 className="widget_title">Quick links</h3>
                                <div className="menu-all-pages-container footer-menu">
                                    <ul className="menu">
                                        <li><a href="/privacy-and-policy">PRIVACY POLICY</a></li>
                                        <li><a href="/terms-and-conditions">TERMS AND CONDITIONS</a></li>
                                        <li><a href="/careers">CAREERS</a></li>
                                        <li><a href="/faq">FAQ's</a></li>
                                        <li><a href="">GET DIRECTION</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <p className="fs-22 mb-2">If you have any queries and concerns - <a href="contact">Contact Us</a></p>
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-auto text-center">
                            <p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2023.All Rights Reserved By <a href="/">Aayush Foods Products</a></p>
                        </div>
                        <div className="col-auto d-none d-md-block">
                            <p className="copyright-text">Website Designed and Developed  By <a href="https://www.vmtechnologies.co.in" target="_blank">VM Technologies</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer> 

        )
    }
}

export default Footer;