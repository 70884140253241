import React, { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function SliderBlock() {
  
  return ( 
    <Swiper 
    modules={[Autoplay, Navigation, Pagination, A11y]}
    cssMode={true}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      navigation={true}
      pagination={{ clickable: true }}
      //onSwiper={(swiper) => console.log(swiper)}
      //onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide><img src="assets/img/hero/hero-img-4-1.jpg" /></SwiperSlide>
      <SwiperSlide><img src="assets/img/hero/hero-img-4-2.jpg" /></SwiperSlide>
      <SwiperSlide><img src="assets/img/hero/slider2.jpg" /></SwiperSlide>
     
      
    </Swiper>
  )
  
}

export default SliderBlock