import React from "react";
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class About extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>About Aaysh Food Products | Coimbatore | Dehydrated vegetables | Spray dried fruits powder | Dried leaves and herbs | Spice Powders</title>
                </Helmet>
                <div className="breadcumb-wrapper" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/breadcumb-bg-2.jpg)`,
                    backgroundSize: 'cover',
                }}>
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">About Us</h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li><a href="/">Home</a></li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <section className=" space-top space-extra-bottom">
  <div className="container">
    <div className="row gx-60">
      <div className="col-lg-6">
        <div className="img-box9">
          <div className="img-1">
            <img src="assets/img/about/about-9-1.jpg" alt="About Image" />
          </div>
          <div className="img-2">
            <img src="assets/img/about/about-9-2.jpg" alt="Aayush Food Products" />
          </div>
        </div>
      </div>
      <div className="col-lg-6 align-self-center">
        <span className="sec-subtitle">our stories</span>
        <h2 className="sec-title">Our Journey Was Start From 2015</h2>
        <p className="mb-30 pb-2">
        Established in 2015, Aayush Food Products is a leading provider of premium quality dehydrated vegetables, fruit powders, herbal teas, and raw spices. Our journey began with a simple yet ambitious goal: to revolutionize the way people perceive and consume food by offering natural, healthy, and convenient alternatives.
        </p>
        <div className="media-style7">          
          <div className="media-body">
            <h3 className="media-title h5">Sustainability Commitment</h3>
            <p className="media-text">
            We are committed to sustainability and environmental responsibility. From eco-friendly packaging materials to ethical sourcing practices, we strive to minimize our ecological footprint and contribute to a healthier planet for future generations.
            </p>
          </div>
        </div>
        <div className="media-style7">          
          <div className="media-body">
            <h3 className="media-title h5">Special Support</h3>
            <p className="media-text">
            We adhere to stringent standards throughout the production process, from sourcing raw materials to packaging the final products. Our state-of-the-art facilities and dedicated team ensure that each item meets our rigorous quality criteria, guaranteeing consistency and excellence with every purchase.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

                <section className="space-top space-extra-bottom">
                    {/*<div className="shape-mockup jump-img d-none d-xl-block" data-left="34%" data-bottom="1%"><img src="assets/img/shape/leaf-1-6.png" alt="shape" /></div>*/}
                    <div className="container">
                        <div className="row justify-content-between gx-0 ">
                            <div className="col-md-10">
                                <span className="sec-subtitle">About Aayush Food Products</span>
                                <h3 className="h3 pe-xxl-5 me-xxl-5 mb-md-5 pb-xl-3">We, Aayush Food Products established in 2015 and we are the leading dehydrated Vegetables & Spray dried Fruit
Powders manufactures in India with a reputation for quality and security of supply on a reliable time. </h3>
                            </div>
                            <div className="col-auto mb-5 mb-md-0">
                                <div className="pt-1 mt-2">
                                    <div className="circle-btn style2">
                                        <a href="service.html" className="btn-icon"><i className="far fa-arrow-right"></i></a>
                                        <div className="btn-text">
                                            <svg viewBox="0 0 150 150">
                                                <text>
                                                    <textPath href="#textPath">Aayush Food Products  |   Coimbatore |</textPath>
                                                </text>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="fs-22 font-title text-title mb-4 mb-lg-5">We are committed to meet your unique and complex requirements by offering excellent logistics support and integrated sourcing,
procuring and warehousing services. Aayush Food Products always goes the extra-mile.</p>

                        <p className="fs-22 font-title text-title mb-4 mb-lg-5">We are totally committed to our customer and work round the
clock in every department to fulfil their demands from production & Marketing, everyone at Aayush Food Products is fully focused
towards producing a quality product that is offered at a competitive price.</p>

                        <blockquote className="vs-quote">
                            <p>
                                “Unlock the Flavor, Preserve the Nutrition. Elevate your cuisine with our premium range of dehydrated vegetables and spray-dried fruits powders. From the fields to your kitchen, we encapsulate nature's goodness, ensuring every meal is a testament to health and taste.”{" "}
                            </p>
                            <cite>Aayush Foods</cite>
                        </blockquote>
                        <div className="row justify-content-between">
                            
                            <div className="col-md-6 col-xl-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="row gx-60">
                                    <div className="col-auto">
                                        <span className="about-number">01</span>
                                    </div>
                                    <div className="col">
                                        <h4 className="fw-medium fs-26 font-body mt-n1 mb-lg-3 pb-lg-1">Ingredient Pipeline</h4>
                                        <div className="list-style1">
                                            <p>Aayush Food Products having a relationship with many of the farmers and suppliers
for many years. These deeply rooted relationships are the reason we have such an
intimate knowledge of our partners’ raw material origin, manufacturing processes,
technologies, and logistics; we showcase this with our exceptional service and
transparency. We know our ingredients extremely well.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="row gx-60">
                                    <div className="col-auto">
                                        <span className="about-number">02</span>
                                    </div>
                                    <div className="col">
                                        <h4 className="fw-medium fs-26 font-body mt-n1 mb-lg-3 pb-lg-1">Technical Sales and Transparency</h4>
                                        <div className="list-style1">
                                            <p> Our well trained Sales Professional Team is ready to support each and every customer in all the possible ways by, sourcing the right material in the right season. Our motto is to identify the needs of our valuable customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inner-wrap1  ">
                            <div
                                className="row vs-carousel"
                                data-slide-show={3}
                                data-lg-slide-show={2}
                                data-md-slide-show={2}
                            >
                                <div className="col-xl-4">
                                    <div className="service-style2">
                                        <div className="vs-icon style3">
                                            <img src="assets/img/about/vission.png" alt="icon" />
                                        </div>
                                        <h3 className="service-title h5">Mission</h3>
                                        <div className="arrow-shape">
                                            <i className="arrow" />
                                            <i className="arrow" />
                                            <i className="arrow" />
                                            <i className="arrow" />
                                        </div>
                                        <p className="service-text">
                                        We are dedicated to being the leading supplier of spices and seasoning products that are driven by innovation, bounded by quality, and delivered with unparalleled service.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="service-style2">
                                        <div className="vs-icon style3">
                                            <img src="assets/img/about/mission.png" alt="icon" />
                                        </div>
                                        <h3 className="service-title h5">Vision</h3>
                                        <div className="arrow-shape">
                                            <i className="arrow" />
                                            <i className="arrow" />
                                            <i className="arrow" />
                                            <i className="arrow" />
                                        </div>
                                        <p className="service-text">
                                        Aayush Food Products established for delivering quality products and
as such we place the highest priority on ensuring our precious standards, which are in living actions.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="service-style2">
                                        <div className="vs-icon style3">
                                            <img src="assets/img/about/quality.png" alt="icon" />
                                        </div>
                                        <h3 className="service-title h5">Quality Policy</h3>
                                        <div className="arrow-shape">
                                            <i className="arrow" />
                                            <i className="arrow" />
                                            <i className="arrow" />
                                            <i className="arrow" />
                                        </div>
                                        <p className="service-text">
                                       Believing is the fact that there is no substitute for quality. Aayush Food Products have been vigorously trying to put in all our efforts to improve the
quality of our products.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <h3>Our Licence And Certificates </h3>
                            <Carousel cols={5} rows={1} gap={10} loop>
      <Carousel.Item>
        <img width="100%" src="assets/img/brand/7.jpg" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="assets/img/brand/6.jpg" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="assets/img/brand/5.jpg" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="assets/img/brand/4.jpg" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="assets/img/brand/3.jpg" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="assets/img/brand/2.jpg" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="assets/img/brand/1.jpg" />
      </Carousel.Item>
    </Carousel>
                            </div>
                        </div>




                    </div>
                </section>
            </div>
        )
    }
}

export default About;