import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class BeetrootSlices extends React.Component {
    render() {
        return (
            <div>
               <Helmet>
                    <title>Beetroot Slices | Dehydrated vegetables Flakes | Spray dried fruits powder | Dried leaves and herbs | Spice Powders</title>
                </Helmet>
                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/beetroot_flakes.png" alt="Beetroot Slices" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/potato_flakes.png" alt="Beetroot Slices" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Beetroot Slices</h2>

                                    <p className="product-text">
                                    We are leading supplier and manufacturer of beetroot products in Tamilnadu. Our offered beetroot flakes and powder are widely acknowledged among our clients owing to its freshness and accurate composition. The provided flake is precisely processed using the quality grade natural ingredients and modern processing technology.
                                    </p>
                                    <p className="product-text">
                                    Aayush Food Products proudly presents our premium-quality carrot flakes, carefully sourced and processed to bring you the essence of fresh carrots in a convenient form. Bursting with vibrant color and natural sweetness, our carrot flakes are a versatile ingredient that adds depth and flavor to a wide range of culinary creations.
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
              <a href="/dehydrated-vegetables-powders">Dehydrated Vegetable Flakes and Powders</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Why Choose Beetroot Slices from Aayush Food Products?</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Intense Flavor:</b> Our dehydrated beetroot slices retain the natural sweetness and earthy flavor of fresh beetroots. They offer a concentrated burst of flavor that adds depth and richness to your culinary creations.</li>
                                            <li><b>Versatile Ingredient: </b>Whether you're adding them to salads, soups, stews, or baked goods, our dehydrated beetroot slices are incredibly versatile. They can be rehydrated and incorporated into a wide range of dishes, or enjoyed as a crunchy snack straight out of the pack.</li>
                                        <li><b>Nutrient-Rich:</b> Beetroot is known for its nutritional benefits, including being rich in vitamins, minerals, and antioxidants. Our dehydrated beetroot slices preserve these nutrients, making them a wholesome addition to your diet.</li>
                                        <li><b>Long Shelf Life:</b> Unlike fresh beetroot, our dehydrated beetroot slices have a long shelf life, allowing you to enjoy their goodness year-round. They're perfect for stocking up your pantry and adding a pop of color and flavor to your meals whenever inspiration strikes.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                        Red Onion flakes are a versatile ingredient that can be used in a variety of recipes to add depth of flavor and aroma. Here are some recipes and uses for garlic flakes:
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Soups and Stews:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>Enhance the flavor and nutritional value of soups and stews by incorporating rehydrated beetroot slices. They add depth and earthiness to vegetable soups, borscht, and hearty stews. Simply add the slices during the cooking process to infuse the dish with their rich flavor.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Roasted Vegetables:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                   <p>Roast rehydrated beetroot slices along with other vegetables like potatoes, carrots, and onions for a colorful and nutritious side dish. Drizzle with olive oil, sprinkle with herbs and spices, and roast until tender and caramelized for a delicious addition to any meal.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/amachur-flakes">
                                            <img
                                                src="assets/img/product/thumb/amachur_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/amachur-flakes">
                                                Amachur Flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/green-chilli-flakes">
                                            <img
                                                src="assets/img/product/thumb/green_chilli_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/green-chilli-flakes">
                                                Green Chilli Flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default BeetrootSlices;