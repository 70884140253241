import React from "react";

class SpraydriedFruitspowder extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="breadcumb-wrapper "
                    data-bg-src="assets/img/breadcumb/breadcumb-bg-4.jpg"
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Fruits Powder <span className="inner-text">( Spray Dried )</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li>
                                        <span className="inner-text">Fruits Powder ( Spray Dried )</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> Spray-Dried Fruit Powders: Nature's Essence in Every Scoop</span>
                                                <h2 className="sec-title4">The Delicate Allure of Spray Dried Fruits Powders</h2>
                                                <p className="sec-text">Nature's goodness, condensed into a convenient form. Enjoy the vibrant taste and nutrition of fresh fruits in an easy-to-use powder. Perfect for enhancing smoothies, baking, or as a quick, healthy snack. Experience the essence of fruits, anytime, anywhere.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="apple-powder">
                                                        <img
                                                            src="assets/img/product/thumb/apple_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="apple-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="apple-powder">
                                                            Apple Powder
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="banana-powder">
                                                        <img
                                                            src="assets/img/product/thumb/banana_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="banana-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="banana-powder">
                                                            Banana Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="lemon-powder">
                                                        <img
                                                            src="assets/img/product/thumb/lemon_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="lemon-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="lemon-powder">
                                                            Lemon Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="amla-powder">
                                                        <img
                                                            src="assets/img/product/thumb/amla_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="amla-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="amla-powder">
                                                            Amla Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="papaya-powder">
                                                        <img
                                                            src="assets/img/product/thumb/papaya_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="papaya-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="papaya-powder">
                                                            Papaya Powder
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="unripe-papaya-powder">
                                                        <img
                                                            src="assets/img/product/p-2-2.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="unripe-papaya-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="unripe-papaya-powder">
                                                            Unripe Papaya Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="orange-powder">
                                                        <img
                                                            src="assets/img/product/thumb/orange_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="orange-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="orange-powder">
                                                            Orange Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="pomegranate-powder">
                                                        <img
                                                            src="assets/img/product/thumb/pomegranate_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="pomegranate-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="pomegranate-powder">
                                                            Pomegranate Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="mango-powder-alponso">
                                                        <img
                                                            src="assets/img/product/thumb/mango_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="mango-powder-alponso" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="mango-powder-alponso">
                                                            Mango Powder Alponso
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="tamarind-powder">
                                                        <img
                                                            src="assets/img/product/thumb/tamarind_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="tamarind-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="tamarind-powder">
                                                            Tamarind Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="litchi-powder">
                                                        <img
                                                            src="assets/img/product/thumb/litchi_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="litchi-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="litchi-powder">
                                                            Litchi Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="kiwi-powder">
                                                        <img
                                                            src="assets/img/product/thumb/kiwi_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="kiwi-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="kiwi-powder">
                                                            Kiwi Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="avacado-powder">
                                                        <img
                                                            src="assets/img/product/thumb/avacado_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="avacado-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="avacado-powder">
                                                            Avacado Powder
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="pineapple-powder">
                                                        <img
                                                            src="assets/img/product/thumb/pineapple_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="pineapple-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="pineapple-powder">
                                                            Pineapple Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="melon-powder">
                                                        <img
                                                            src="assets/img/product/thumb/melon_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="melon-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="melon-powder">
                                                            Watermelon Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="peach-powder">
                                                        <img
                                                            src="assets/img/product/thumb/peach_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="peach-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="peach-powder">
                                                            Peach Powder
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="black-grapes-powder">
                                                        <img
                                                            src="assets/img/product/thumb/black_grapes_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="black-grapes-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="black-grapes-powder">
                                                            Black Grapes Powder 
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="chikoo-powder">
                                                        <img
                                                            src="assets/img/product/thumb/chikoo_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="chikoo-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="chikoo-powder">
                                                            Chikoo Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="guava-powder">
                                                        <img
                                                            src="assets/img/product/thumb/guava_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="guava-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="guava-powder">
                                                            Guava Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="fig-powder">
                                                        <img
                                                            src="assets/img/product/thumb/fig_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="fig-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="fig-powder">
                                                            Fig Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="vanilla-powder">
                                                        <img
                                                            src="assets/img/product/thumb/vanilla_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="vanilla-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="vanilla-powder">
                                                            Vanilla Powder
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="cocoa-powder">
                                                        <img
                                                            src="assets/img/product/thumb/cocoa_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="cocoa-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="cocoa-powder">
                                                            Cocoa Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dates-powder">
                                                        <img
                                                            src="assets/img/product/thumb/dates_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="dates-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dates-powder">
                                                            Dates Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="cheddar-cheese-powder">
                                                        <img
                                                            src="assets/img/product/thumb/cheddar_cheese_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="cheddar-cheese-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="cheddar-cheese-powder">
                                                            SD Cheddar Cheese Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="curd-powder">
                                                        <img
                                                            src="assets/img/product/thumb/curd_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="curd-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="curd-powder">
                                                            Curd Powder
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="yogurt-powder">
                                                        <img
                                                            src="assets/img/product/thumb/yogurt_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="yogurt-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="yogurt-powder">
                                                            Yogurt Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="tender-coconut-powder">
                                                        <img
                                                            src="assets/img/product/thumb/tender_coconut_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="tender-coconut-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="tender-coconut-powder">
                                                            Tender Coconut Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="honey-powder">
                                                        <img
                                                            src="assets/img/product/thumb/honey_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="honey-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="honey-powder">
                                                            Honey Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="caramel-powder">
                                                        <img
                                                            src="assets/img/product/thumb/caramel_powder.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="caramel-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                      
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="caramel-powder">
                                                            Caramel Powder
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}

export default SpraydriedFruitspowder;