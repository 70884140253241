import React from 'react';

class Top extends React.Component{
    render(){
        return (
            <React.Fragment>
            <div className="preloader">
            <button className="vs-btn preloaderCls">Cancel Preloader </button>
            <div className="preloader-inner">
                <div className="loader"></div>
            </div>
            </div>
            <svg viewBox="0 0 150 150" className="svg-hidden">
                <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
            </svg>
            <div className="vs-menu-wrapper">
            <div className="vs-menu-area text-center">
                <button className="vs-menu-toggle"><i className="fal fa-times"></i></button>
                <div className="mobile-logo">
                    <a href="#"><img src="assets/img/logo_t.png" alt="Wellnez"/></a>
                </div>
                <div className="vs-mobile-menu">
                    <ul>
                        <li>
                            <a href="#">Home</a>
                        </li>
                        <li>
                            <a href="#">About Aayush</a>
                        </li>
                        <li>
                            <a href="#">Gallery</a>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Products</a>
                            <ul className="sub-menu">
                                <li><a href="#">Dehydrated Vegetables</a>
                                    <ul className="sub-menu">
                                        <li><a href="#">Dehydrated Amchur (Mango)</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Carrot</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">View More</a></li>
                                    </ul>
                                </li>
                                <li><a href="#">Dehydrated Vegetables</a>
                                    <ul className="sub-menu">
                                        <li><a href="#">Dehydrated Amchur (Mango)</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Carrot</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">View More</a></li>
                                    </ul>
                                </li>
                                <li><a href="#">Dehydrated Vegetables</a>
                                    <ul className="sub-menu">
                                        <li><a href="#">Dehydrated Amchur (Mango)</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Carrot</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">View More</a></li>
                                    </ul>
                                </li><li><a href="#">Dehydrated Vegetables</a>
                                    <ul className="sub-menu">
                                        <li><a href="#">Dehydrated Amchur (Mango)</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Carrot</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">Dehydrated Vegetables</a></li>
                                        <li><a href="#">View More</a></li>
                                    </ul>
                                </li>
                            </ul>
                        
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Applications</a>
                            <ul className="sub-menu">
                                <li><a href="#">Dehydrated Amchur (Mango)</a></li>
                                <li><a href="#">Dehydrated Vegetables</a></li>
                                <li><a href="#">Dehydrated Vegetables</a></li>
                                <li><a href="#">Dehydrated Vegetables</a></li>
                                <li><a href="#">Dehydrated Carrot)</a></li>
                                <li><a href="#">Dehydrated Vegetables</a></li>
                                <li><a href="#">Dehydrated Vegetables</a></li>
                                <li><a href="#">Dehydrated Vegetables</a></li>
                                <li><a href="#">View More</a></li>
                            </ul>
                        </li>                   
                        <li>
                            <a href="#">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        {/*<div className="sidemenu-wrapper d-none d-lg-block">
            <div className="sidemenu-content">
                <button className="closeButton sideMenuCls"><i className="far fa-times"></i></button>
                <div className="widget  ">
                    <div className="footer-logo">
                        <img src="assets/img/logo_t.png" alt="logo"/>
                    </div>
                    <div className="info-media1">
                        <div className="media-icon"><i className="fal fa-map-marker-alt"></i></div>
                        <span className="media-label">No :54-A-1/74,<br/> Athipalayam Road,<br/> Ganapathy, <br/> coimbatore-641 006.</span>
                    </div>
                    <div className="info-media1">
                        <div className="media-icon"><i className="far fa-phone-alt "></i></div>
                        <span className="media-label"><a href="tel:04224377322" className="text-inherit"> 0422 - 4377322</a></span>
                    </div>
                    <div className="info-media1">
                        <div className="media-icon"><i className="fal fa-envelope"></i></div>
                        <span className="media-label"><a className="text-inherit" href="mailto:sales@aayushfoodingredients.com">sales@aayushfoods.in</a></span>
                    </div>
                </div>
            </div>
        </div>

        <div className="popup-search-box d-none d-lg-block  ">
            <button className="searchClose"><i className="fal fa-times"></i></button>
            <form action="#">
                <input type="text" className="border-theme" id="" name="" placeholder="What are you looking for" />
                <button type="submit"><i className="fal fa-search"></i></button>
            </form>
        </div> */}

        </React.Fragment>
        )
    }
}


export default Top;
