import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class Fig extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Fig Powder | Spray dried fruits powder | Dehydrated vegetables Flakes |Dried leaves and herbs | Spice Powders</title>
                </Helmet>
                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/fig_powder.png" alt="Fig Powder " />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/fig_powder1.png" alt="Fig Powder " />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Fig Powder</h2>

                                    <p className="product-text">
                                    Discover the natural sweetness and nutritional goodness of our premium Fig Powder, made from carefully selected figs. Packed with vitamins, minerals, and fiber, our fig powder is a versatile ingredient that adds a delightful sweetness to a variety of dishes. The product is made from the quality-tested fig and known for its purity and quality. Dried Fig Powder, that we offer, is accessible in different packaging sizes and that too at nominal prices.
                                    </p>
                                    <p className="product-text">
                                    Ready to experience the natural sweetness and nutritional benefits of our Fig Powder? Browse our selection of Fig Powder products and discover a healthier way to sweeten your favorite recipes. With secure online ordering and fast shipping, getting your hands on our premium Fig Powder has never been easier. Enhance your culinary creations and enjoy the goodness of nature with Aayush Food Products.
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="spray-dried-fruits-powder">Spray Dried Fruits Powder</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Why Choose Fig Powder from Aayush Food Products?</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Pure and Natural:</b> Our Fig Powder is made from 100% pure figs, with no added sugars or preservatives. Experience the true essence of figs in every spoonful, without any artificial flavors or additives.</li>
                                            <li><b>Rich in Nutrients: </b>Figs are naturally rich in vitamins, minerals, and antioxidants, making our Fig Powder a wholesome addition to your diet. Enjoy the benefits of potassium, calcium, and dietary fiber with every serving.</li>
                                        <li><b>Versatile Use: </b>  From baking to cooking to beverages, our Fig Powder is a versatile ingredient that can be incorporated into a wide range of recipes. Use it as a natural sweetener in desserts, smoothies, or savory dishes for a unique and delicious flavor.</li>
                                        <li><b>Easy to Use: </b> With its fine texture and smooth consistency, our Fig Powder blends seamlessly into your recipes, adding sweetness and depth of flavor without any grittiness or clumping.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    Looking for inspiration on how to use our Fig Powder? Here are some delicious recipe ideas to get you started:
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Fig and Nut Energy Bars: </h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>Whip up a batch of energy bars using our Fig Powder as a natural sweetener. Combine fig powder with nuts, seeds, and oats for a nutritious and satisfying snack.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Fig and Honey Glazed Chicken: </h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                   <p> Create a sweet and savory glaze for chicken by combining fig powder with honey, balsamic vinegar, and herbs. Brush the glaze over chicken breasts or thighs before baking or grilling for a flavorful and succulent dish.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/green-chilli-flakes">
                                            <img
                                                src="assets/img/product/thumb/green_chilli_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/green-chilli-flakes">
                                                Green Chilli Flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Fig;