import React from "react";

class SeasoningHerbs extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="breadcumb-wrapper "
                    data-bg-src="assets/img/breadcumb/breadcumb-bg-4.jpg"
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Seasoning <span className="inner-text">Herbs</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        Seasoning <span className="inner-text">Herbs</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> 100% Natural Dehydrated Foods</span>
                                                <h2 className="sec-title4">Vegetables, Fruits & Leaves</h2>
                                                <p className="sec-text">Lorem ipsum dolor sit amet conse ctetur adipisicing elit. Omnis, totam molestias.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="garlic-flakes">
                                                        <img
                                                            src="assets/img/product/p-2-1.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="garlic-flakes">
                                                            Rosemary leaves (Imported)
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-2.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Rosemary leaves (Indian Origin)
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-3.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Parsely leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-4.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Thyme leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-5.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Oregano leaves
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-6.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Sweet basil leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-7.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Sage leaves whole
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-8.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Majoram leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-9.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="shop-details.html">
                                                            Mixed herbs
                                                            </a>
                                                        </h3>
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}

export default SeasoningHerbs;