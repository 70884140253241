import React from 'react';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      showToggle: false
    };
  }

  togglePopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };
  togglePopup1 = () => {
    this.setState({ showToggle: !this.state.showToggle });
  };

  closePopup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showPopup: false });
  };
  closePopup1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showToggle: false });
  };
  render() {
    return (
      <div>
        <div className={this.state.showPopup ? "popup-search-box show" : "popup-search-box"}>
          <button className="searchClose" onClick={this.closePopup}>
            <i className="fal fa-times" />
          </button>
          <form action="/">
            <input
              type="text"
              className="border-theme"
              placeholder="What are you looking for"
            />
            <button type="submit">
              <i className="fal fa-search" />
            </button>
          </form>
        </div>

        <div className={this.state.showToggle ? "sidemenu-wrapper show" : "sidemenu-wrapper"}>
          <div className="sidemenu-content">
            <button className="closeButton sideMenuCls" onClick={this.closePopup1}>
              <i className="far fa-times" />
            </button>
            <div className="widget  ">
              <div className="footer-logo">
                <img src="assets/img/logo_t.png" alt="logo" />
              </div>
              <div className="info-media1">
                <div className="media-icon">
                  <i className="fal fa-map-marker-alt" />
                </div>
                <span className="media-label">
                  <h5>Address :</h5>
                  No :54-A-1/74,
                  <br /> Athipalayam Road,
                  <br />  Ganapathy, <br />  Coimbatore-641 006.
                </span>
              </div>
              <div className="info-media1">
                <div className="media-icon">
                  <i className="far fa-phone-alt " />
                </div>
                <span className="media-label">
                  <a href="#" className="text-inherit">
                    0422 - 4377322
                  </a>
                </span>
              </div>
              <div className="info-media1">
                <div className="media-icon">
                  <i className="fal fa-envelope" />
                </div>
                <span className="media-label">
                  <a className="text-inherit" href="#" >
                    sales@aayushfoods.in
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>


        <header className="vs-header header-layout2">
          <div className="header-top">
            <div className="container">
              <div className="row justify-content-between align-items-center flex-nowrap">
                <div className="col-sm-auto text-center">
                  <div className="header-links style-white">
                    <ul>
                      <li className="d-xxl-inline-block"><i className="far fa-map-marker-alt"></i>Ganapathy,Coimbatore-641 006.</li>
                      <li><i className="far fa-envelope"></i><a href="mailto: info@aayushfoods.in">info@aayushfoods.in</a></li>
                      <li><i className="far fa-envelope"></i><a href="mailto: sales@aayushfoods.in">sales@aayushfoods.in</a></li>
                      <li><i className="far fa-phone-alt"></i><a href="tel:+04224377322">0422 - 4377322</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto d-none d-sm-block">
                  <div className="social-style1">
                    <span className="social-title">Follow Us:</span>
                    <a href="https://www.facebook.com/Aayushfood/" target='_blank'><i className="fab fa-facebook-f"></i></a>
                    <a href="https://www.instagram.com/aayushfoods/" target='_blank'><i className="fab fa-instagram"></i></a>
                    <a href="https://t.ly/JvIS1" target='_blank'><i className="fab fa-google"></i></a>
                    <a href="https://www.linkedin.com/company/aayush-food-products/" target='_blank'><i className="fab fa-linkedin-in"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-wrap">
            <div className="sticky-active">
              <div className="menu-area">
                <div className="menu-inner">
                  <div className="container">
                    <div className="row justify-content-between align-items-center gx-60">
                      <div className="col">
                        <div className="header-logo">
                          <a href="#"><img src="assets/img/logo_t.png" alt="logo" /></a>
                        </div>
                      </div>
                      <div className="col-auto">
                        <nav className="main-menu menu-style1 d-none d-lg-block">
                          <ul>

                            <li className="mega-menu-wrap">
                              <a href="/">Home</a>
                            </li>
                            <li><a href="about">About</a></li>
                                                       
                            <li className="menu-item-has-children">
                              <a href="aayush-products">Products</a>
                              <ul className="sub-menu">
                                <li><a href="dehydrated-vegetables-powders">Dehydrated Vegetable Flakes & Powders</a></li>
                                <li><a href="spray-dried-fruits-powder">Spray Dried Fruits Powders</a></li>
                                <li><a href="dried-leaves-powder">Dried Leaves & Herbs</a></li>
                                <li><a href="raw-spices-and-powders">Raw Spices & Spice Powders</a></li>
                                <li><a href="dried-flowers-and-powders">Dried Flowers & Powders</a></li>
                                <li><a href="tea-bag-cut">TEC - Tea Bag Cut</a></li>
                                <li><a href="retail-brand-hayaku-flavours">Retail Brand - Hayaku Flavours</a></li>
                                
                              </ul>
                            </li>
                            <li>
                              <a href="application">Application</a>
                            </li>
                            <li>
                              <a href="contact">Contact</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div className="col-auto">
                        <div className="header-icons">
                          {/*<a href="get-quote" className="vs-btn style8 d-none d-xl-inline-block">Quote</a>*/}
                          <button className="searchBoxTggler" onClick={this.togglePopup} ><i className="far fa-search"></i></button>
                          <button className="bar-btn sideMenuToggler d-none d-xl-inline-block" onClick={this.togglePopup1}>
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                          </button>
                          <button className="vs-menu-toggle d-inline-block d-lg-none" type="button"><i className="fal fa-bars"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

    )
  }
}

export default Header;
