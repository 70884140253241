import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';


const Contact = () => {
    const form = useRef();
    const navigate = useNavigate();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_uq3z988', 'template_b5xso81', form.current, {
        publicKey: 'Kez2yfDVM7Dv9X5gx',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          navigate('/contact');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

    
        return (
            <div>
                <div
                    className="breadcumb-wrapper "
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/contactus.jpg)`,
                        backgroundSize: 'cover',
                    }}
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Contact <span className="inner-text">Us</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        Contact <span className="inner-text">Us</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section className=" space">
                    <div className="container">
                        <div className="row mb-40">
                        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="contact-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.245483310854!2d76.9733299!3d11.0201996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8584f0a7d3cc5%3A0xe5c394a722856d1d!2sAayush+Food+Products!5e0!3m2!1sta!2sin!4v1486726037188"
                                        width={600}
                                        height={350}
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />
                                </div>                                
                            </div>
                        </div>

                        <div className="row gx-70">
                            <div
                                className="col-lg-5  wow fadeInUp"
                                data-wow-delay="0.2s"
                            >
                                <div className="text-center text-lg-start">
                                    <span className="sec-subtitle">Experience</span>
                                    <h2 className="sec-title3 h1 text-uppercase mb-xxl-2 pb-xxl-1">
                                        Get in <span className="text-theme">Touch</span>
                                    </h2>
                                    
                                </div>
                                <form
                                    method="POST"
                                    className="ajax-contact form-style6"
                                    ref={form}
                                    onSubmit={sendEmail}
                                   
                                >
                                    <div className="form-group">
                                        <input type="text" name="name" id="name" placeholder="Your Name*"  required/>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Your Email*"
                                           
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="mobile" id="mobile" placeholder="Your Mobile Number*"  required/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="product" id="product" placeholder="Enter Product*"  required/>
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            id="message"
                                            placeholder="Message*"
                                            
                                        />
                                    </div>
                                    <input type='hidden' name="to_name" id="to_name" value="Aayush Food Products" />
                                    <button className="vs-btn" type="submit">
                                        Send Message
                                    </button>
                                    <p className="form-messages" />
                                </form>
                            </div>
                            <div className="col-lg-7">
                            <div className="row">
  <div className="col-12 mb-5">
    <h2 className="h3 mb-4 mt-n2">Get Expert Consultation</h2>
    <p className="fs-md font-title mb-4">
    Join us in our journey towards healthier, more flavorful living. Explore our range of products and discover the essence of Aayush Food Products. For inquiries, collaborations, or wholesale orders, please contact us at-
    </p>
    <div className="row gy-2">
      <div className="col-auto">
        <p className="vs-info">
          <i className="fal fa-envelope" />
          <a href="mailto:info@aayushfoods.in" className="text-inherit">
          info@aayushfoods.in
          </a>
        </p>
      </div>
      <div className="col-auto">
        <p className="vs-info">
          <i className="fal fa-phone-alt" />
          <a href="tel:+04224377322" className="text-inherit">
          +0422 - 4377322
          </a>
        </p>
      </div>
    </div>
  </div>
 
</div>

                                <div className="contact-table">
                                    <div className="tr">
                                        <div className="tb-col">
                                            <span className="th">Address :</span>
                                            <span className="td">54-A-1/74, Athipalayam Road, Ganapathy, Coimbatore-641006</span>
                                        </div>
                                    </div>
                                    <div className="tr">
                                        <div className="tb-col">
                                            <span className="th">email :</span>
                                            <span className="td">
                                                <a href="mailto:sales@aayushfoods.in " className="text-inherit">
                                                sales@aayushfoods.in 
                                                </a>
                                            </span>
                                        </div>
                                        <div className="tb-col">
                                            <span className="th">time : </span>
                                            <span className="td">09 : 00 AM - 06 : 30 PM</span>
                                        </div>
                                    </div>
                                </div>
                               
                                
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    
};

export default Contact;
