import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class PeppermintLeavesTbc extends React.Component {
    render() {
        return (
            <div>
               <Helmet>
                    <title>Peppermint Leaves - Tea Bag Cuts (TBC) | Dehydrated vegetables Flakes | Spray dried fruits powder | Dried leaves and herbs | Raw Spice and Powders </title>
                </Helmet>

                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Lemon_Peel.png" alt="Peppermint Leaves" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Lemon_Peel.png" alt="Peppermint Leaves" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Peppermint Leaves</h2>

                                    <p className="product-text">
                                    At Aayush Food Products, we're passionate about providing premium quality teas and herbal products that enhance your well-being and delight your senses. Our Peppermint Leaves Tea Bags are a testament to our commitment to excellence, offering a refreshing blend that rejuvenates the senses and uplifts the spirit. Join us in embracing the revitalizing power of Aayush Peppermint Leaves Tea and indulge in moments of pure refreshment and vitality.
                                    </p>
                                    <p className="product-text">
                                    Invigorate your senses with the cooling freshness of Aayush Peppermint Leaves Tea Bags. Sourced from the finest peppermint fields, our tea bags offer a burst of minty flavor and aroma that revitalizes the palate and uplifts the spirit. Whether enjoyed as a morning energizer or an evening wind-down, Aayush Peppermint Leaves Tea is your perfect companion for moments of pure refreshment and vitality.
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="/tea-bag-cut">Tea Bag Cut (TBC)</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    Brewing Instructions:
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Features & Benefits:</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Premium Quality:</b> Carefully selected peppermint leaves ensure optimal freshness and flavor, delivering a truly invigorating tea experience.</li>
                                            <li><b>Cooling Flavor Profile: </b>Experience the refreshing and minty notes of peppermint, which awaken the senses and refresh the palate with every sip.</li>
                                            <li><b>Digestive Support: </b> Peppermint tea can aid digestion and promote gastrointestinal health, soothing the stomach and alleviating digestive discomfort.</li>
                                            <li><b>Energy Boost:   </b>Peppermint tea provides a natural energy boost, helping you stay alert and focused throughout the day.</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    <h4>Brewing Instructions:</h4>
                                                    </p>
                                                    <div className="vs-post-comment">                                                        
                                                        <div className="comment-content">
                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>1. Boil water and pour it into a cup.</p>
                                                                    <p>2. Add one Aayush Peppermint Leaves Tea Bag.</p>
                                                                    <p>3. Steep for 3-5 minutes to release the full flavor and aroma.</p>
                                                                    <p>4. Remove the tea bag and savor the cooling and revitalizing taste of peppermint tea.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/lemon-peel">
                                            <img
                                                src="assets/img/product/thumb/lemon_peel.png"
                                                alt="lemon-peel"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/lemon-peel">
                                                Lemon peel
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/orange-peel">
                                            <img
                                                src="assets/img/product/thumb/lemon_grass.png"
                                                alt="orange-peel"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/orange-peel">
                                                Orange Peel
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/ginger-tbc">
                                            <img
                                                src="assets/img/product/thumb/ginger_tbc.png"
                                                alt="ginger-tbc"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/ginger-tbc">
                                                Ginger
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/cardamom-tbc">
                                            <img
                                                src="assets/img/product/thumb/cardamom_tbc.png"
                                                alt="cardamom-tbc"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/cardamom-tbc">
                                                Cardamom
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default PeppermintLeavesTbc;