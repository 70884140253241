import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class AshwagandhaRoot extends React.Component {
    render() {
        return (
            <div>
               <Helmet>
                    <title>Ashwagandha Root - Tea Bag Cuts (TBC) | Dehydrated vegetables Flakes | Spray dried fruits powder | Dried leaves and herbs | Raw Spice and Powders </title>
                </Helmet>

                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Lemon_Peel.png" alt="Ashwagandha Root" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Lemon_Peel.png" alt="Ashwagandha Root" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Ashwagandha Root</h2>

                                    <p className="product-text">
                                    Embark on a journey of holistic wellness with Aayush Ashwagandha Root Tea Bags, crafted to harness the ancient wisdom of Ayurveda. Sourced from the pristine fields of India, our tea bags offer a nourishing and grounding experience, allowing you to reconnect with your inner balance and vitality. Each sip of our Ashwagandha Root tea is a tribute to the age-old tradition of Ayurveda, promoting harmony and well-being in every cup.
                                    </p>
                                    
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="/tea-bag-cut">Tea Bag Cut (TBC)</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    Brewing Instructions:
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Features & Benefits:</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Premium Quality:</b> Carefully selected Ashwagandha roots ensure purity and potency, delivering maximum benefits.</li>
                                            <li><b>Traditional Wisdom: </b>Rooted in Ayurvedic principles, our tea bags offer holistic support for mind, body, and spirit.</li>
                                            <li><b>Adaptogenic Properties: </b> Ashwagandha is revered for its adaptogenic qualities, helping the body cope with stress and promoting resilience.</li>
                                            <li><b>Balancing Energy:  </b>Experience a sense of calm and vitality as Ashwagandha root tea harmonizes your body's natural rhythms.</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    <h4>Brewing Instructions:</h4>
                                                    </p>
                                                    <div className="vs-post-comment">                                                        
                                                        <div className="comment-content">
                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>1. Boil water and pour it into a cup.</p>
                                                                    <p>2. Add one Ashwagandha Root Tea Bag.</p>
                                                                    <p>3. Steep for 3-5 minutes to release the full flavor and aroma.</p>
                                                                    <p>4. Remove the tea bag and enjoy the crisp, refreshing taste of lemongrass tea.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/lemon-peel">
                                            <img
                                                src="assets/img/product/thumb/lemon_peel.png"
                                                alt="lemon-peel"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/lemon-peel">
                                                Lemon peel
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/orange-peel">
                                            <img
                                                src="assets/img/product/thumb/lemon_grass.png"
                                                alt="orange-peel"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/orange-peel">
                                                Orange Peel
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/ginger-tbc">
                                            <img
                                                src="assets/img/product/thumb/ginger_tbc.png"
                                                alt="ginger-tbc"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/ginger-tbc">
                                                Ginger
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/cardamom-tbc">
                                            <img
                                                src="assets/img/product/thumb/cardamom_tbc.png"
                                                alt="cardamom-tbc"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/cardamom-tbc">
                                                Cardamom
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default AshwagandhaRoot;