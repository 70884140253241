import React from "react";

class DehydratedVegetables extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="breadcumb-wrapper "
                    data-bg-src="assets/img/breadcumb/breadcumb-bg-4.jpg"
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                            DEHYDRATED VEGETABLES <span className="inner-text">Flakes & POWDERS</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                    DEHYDRATED VEGETABLES Flakes & POWDERS
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> 100% Natural Dehydrated Foods</span>
                                                <h2 className="sec-title4">Vegetables Flakes, Slices & Whole and Powders</h2>
                                                <p className="sec-text">Our company is well-known for offering Dehydrated vegetable Flake, with crunchy texture. Processed from fresh vegetables, the proffered flakes come in irregular size, free from contaminants. Bright green, white and yellow in color, the offered vegetables are free from discoloration. Dehydrates vegetable flakes are provided in standardized packaging. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="garlic-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/garlic_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="garlic-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="garlic-flakes">
                                                                Garlic Flakes
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="red-onion-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/red_onion_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="red-onion-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="red-onion-flakes">
                                                                Red Onion Flakes
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="white-onion-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/white_onion_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="white-onion-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="white-onion-flakes">
                                                                White Onion Flakes
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="carrot-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/carrot_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="carrot-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="carrot-flakes">
                                                                Carrot Flakes
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="potato-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/potato_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="potato-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="potato-flakes">
                                                                Potato Flakes
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="/cabbage-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/cabbage_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="/cabbage-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="/cabbage-flakes">
                                                                Cabbage Flakes
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="green-chilli-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/green_chilli_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="green-chilli-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="green-chilli-flakes">
                                                                Green Chilli Flakes
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="tomato-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/tomato_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="tomato-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="tomato-flakes">
                                                                Tomatto Flakes
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="amachur-flakes">
                                                        <img
                                                            src="assets/img/product/p-2-9.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="amachur-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="amachur-flakes">
                                                                Amachur Flakes
                                                            </a>
                                                        </h3>
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="french-beans-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/french_bean_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="french-beans-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="french-beans-flakes">
                                                                French Beans Flakes
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="red-capsicum-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/red_pepper_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="red-capsicum-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="red-capsicum-flakes">
                                                            Red bell pepper flakes (Red Capsicum)
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="green-capsicum-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/green_pepper_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="green-capsicum-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="green-capsicum-flakes">
                                                            Green bell pepper flakes (Green Capsicum)
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="bittergourd-slices">
                                                        <img
                                                            src="assets/img/product/thumb/bitergourd_slices.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="bittergourd-slices" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="bittergourd-slices">
                                                            Bittergourd Slices
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="drumstick-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/drumstick_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="drumstick-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="drumstick-flakes">
                                                            Drumstick Flakes
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="small-onion-flakes">
                                                        <img
                                                            src="assets/img/product/p-2-12.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="small-onion-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="small-onion-flakes">
                                                            Small Onion Flakes
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="cauliflower-flakes">
                                                        <img
                                                            src="assets/img/product/p-2-12.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="cauliflower-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="cauliflower-flakes">
                                                            Cauliflower Flakes
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="okra-flakes">
                                                        <img
                                                            src="assets/img/product/p-2-12.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="okra-flakes" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="okra-flakes">
                                                            Okra Flakes
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="cucumber-slices">
                                                        <img
                                                            src="assets/img/product/p-2-12.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="cucumber-slices" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="cucumber-slices">
                                                            Cucumber Slices
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="green-peas-whole">
                                                        <img
                                                            src="assets/img/product/p-2-12.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="green-peas-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                      
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="green-peas-whole">
                                                            Green Peas Whole
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="beetroot_slices">
                                                        <img
                                                            src="assets/img/product/thumb/beetroot_flakes.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="beetroot_slices" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="beetroot_slices">
                                                            Beetroot Slices
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}

export default DehydratedVegetables;