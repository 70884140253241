import React from 'react';
import Carousel from 'react-grid-carousel'
import { Helmet } from 'react-helmet';

class Fenugreek extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Fenugreek (Methi) Laves and Powder | Dried Leaves and Herbs | Spray dried fruits powder | Dehydrated vegetables Flakes | Spice Powders</title>
                </Helmet>
                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/potato_flakes.png" alt="Fenugreek (Methi) Laves and Powder" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/potato_flakes.png" alt="Fenugreek (Methi) Laves and Powder" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Fenugreek Leaves and Powder</h2>

                                    <p className="product-text">
                                    We bring forth an extensive range of superior quality Dehydrated Fenugreek Leaves. Our offered fenugreek leaves are hygienically processed under the guidance of our skilled professionals in accordance with internationally approved quality standards. 
                                    </p>
                                    <p className="product-text">
                                    Elevate your dishes with the unique flavor and aroma of fenugreek (methi) leaves and powder from our premium collection. Sourced from the finest fenugreek plants, our dried leaves and powder offer a burst of natural freshness and a hint of bitterness that adds depth to your culinary creations. Ready to elevate your dishes with the unique flavor of Fenugreek (Methi) Leaves and Powder? Browse our collection and unlock the culinary possibilities with fenugreek. With secure online ordering and fast shipping, adding fenugreek leaves and powder to your pantry has never been easier. Experience the aromatic taste of fenugreek from Aayush Food Products.
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="spray-dried-fruits-powder">Spray Dried Fruits Powder</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Why Choose Fenugreek (Methi) Leaves and powder from Aayush Food Products?</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Pure Natural Flavor: </b> Our Fenugreek Leaves and Powder are carefully dried and ground to preserve their natural freshness and aromatic flavor. Each leaf and spoonful of powder delivers the distinctive bitter-sweet taste and earthy aroma of fenugreek, enhancing the taste of your dishes.</li>
                                            <li><b>Versatile Use:  </b>Whether you're preparing Indian curries, Middle Eastern stews, or Mediterranean dishes, our fenugreek leaves and powder are versatile ingredients that add depth and complexity to any recipe. From seasoning to marinating, their unique flavor profile shines through.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    Fenugreek (Methi) Leaves and Powder are a versatile ingredient that can be used in a variety of recipes to add depth of flavor and aroma. Here are some recipes and uses for Fenugreek (Methi) Leaves and Powder:
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Indian Curries:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>Add dried fenugreek leaves to curries, dals, and vegetable dishes for a distinct flavor and aroma. They complement spices like cumin, coriander, and turmeric, adding depth and complexity to Indian recipes.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Breads and Flatbreads:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                   <p>Sprinkle fenugreek leaves or powder over dough for breads, flatbreads, and savory pastries. Their aromatic flavor adds depth to baked goods and creates a delightful aroma while baking.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/green-chilli-flakes">
                                            <img
                                                src="assets/img/product/thumb/green_chilli_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/green-chilli-flakes">
                                                Green Chilli Flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Fenugreek;