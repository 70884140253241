import React from "react";

class TeabagCut extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="breadcumb-wrapper" style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/products.jpg)`,
                        backgroundSize: 'cover',
                    }}
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Tea Bag Cut <span className="inner-text"> ( TBC )</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <span className="inner-text"> Tea Bag Cut (TBC)</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> Introducing Our Signature Tea Bag Cut</span>
                                                <h2 className="sec-title4">Elevating Your Tea Experience</h2>
                                                <p className="sec-text">Our signature tea bag cut (TBC) embodies the epitome of excellence in tea craftsmanship. Meticulously curated blends of premium tea leaves, herbs, and spices are precisely cut and enclosed in our innovative tea bags, ensuring an unparalleled infusion experience with every cup.Experience the artistry of tea with our signature tea bag cut – a testament to quality, innovation, and the timeless tradition of tea-making expertise. Elevate your tea ritual and indulge in a world of flavor, one cup at a time.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="orange-peel">
                                                        <img
                                                            src="assets/img/product/p-2-1.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">                                                       
                                                        <a href="orange-peel" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="orange-peel">
                                                            Orange Peel
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="lemon-peel">
                                                        <img
                                                            src="assets/img/product/p-2-2.png"
                                                            alt="lemon-peel"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="lemon-peel" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="lemon-peel">
                                                            Lemon Peel
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="lemon-grass">
                                                        <img
                                                            src="assets/img/product/p-2-3.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="lemon-grass" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="lemon-grass">
                                                            Lemon Grass
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="ashwagandha-root">
                                                        <img
                                                            src="assets/img/product/p-2-4.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="ashwagandha-root" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="ashwagandha-root">
                                                            Ashwagandha Root
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="cinnamon-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-5.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="cinnamon-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="cinnamon-tbc">
                                                            Cinnamon 
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="black-pepper-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-6.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="black-pepper-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="black-pepper-tbc">
                                                            Black Pepper
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="cardamom-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-7.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="cardamom-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="cardamom-tbc">
                                                            Cardamom
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="ginger-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-8.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="ginger-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="ginger-tbc">
                                                            Ginger
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="turmeric-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-9.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="turmeric-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="turmeric-tbc">
                                                            Turmeric
                                                            </a>
                                                        </h3>
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="fennel-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="fennel-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="fennel-tbc">
                                                            Fennel
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="raspberry-leaves">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="raspberry-leaves" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="raspberry-leaves">
                                                            Raspberry leaves 
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="rose-petals">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="rose-petals" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="rose-petals">
                                                            Rose petals
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="mint-leaves-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="mint-leaves-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="mint-leaves-tbc">
                                                            Mint leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="hibiscus-flower-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="hibiscus-flower-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="hibiscus-flower-tbc">
                                                            Hibiscus Flower
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="stevia">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="stevia" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="stevia">
                                                            Stevia
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="moringa-leaves">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="moringa-leaves" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="moringa-leaves">
                                                            Moringa leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="tulsi">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="tulsi" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="tulsi">
                                                            Tulsi (Rama, Krishna)
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="curry-leaves-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="curry-leaves-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="curry-leaves-tbc">
                                                            Curry Leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="spearmint-leaves-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="spearmint-leaves-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="spearmint-leaves-tbc">
                                                            Spearmint Leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="peppermint-leaves-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="peppermint-leaves-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="peppermint-leaves-tbc">
                                                            Peppermint Leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="nutmeg-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="nutmeg-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="nutmeg-tbc">
                                                            Nutmeg
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="ashwagandha-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="ashwagandha-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="ashwagandha-tbc">
                                                            Ashwagandha
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="licorice">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="licorice" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="licorice">
                                                            Licorice
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="bay-leaves-tbc">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="bay-leaves-tbc" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="bay-leaves-tbc">
                                                            Bay Leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="rosemary-imported">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="rosemary-imported" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="rosemary-imported">
                                                            Rosemary - Imported
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="sage">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="sage" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="sage">
                                                            Sage 
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="rosehips">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="rosehips" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="rosehips">
                                                            Rosehips
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}

export default TeabagCut;