import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

class Poppy extends React.Component {
    render() {
        return (
            <div>
               <Helmet>
                    <title>Poppy Seeds - Raw spices and spice powders | Dehydrated vegetables Flakes | Spray dried fruits powder | Dried leaves and herbs </title>
                </Helmet>

                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Red_chilli_flakes.png" alt="Poppy Seeds" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/Red_chilli_flakes1.png" alt="Poppy Seeds" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Poppy Seeds</h2>

                                    <p className="product-text">
                                    Immerse yourself in the delicate crunch and nutty flavor of poppy seeds, tiny yet mighty seeds that elevate your culinary creations with their unique texture and taste. Sourced from the opium poppy plant, our premium poppy seeds are meticulously selected to ensure optimal freshness and quality. Each tiny seed is a powerhouse of flavor, adding a subtle nuttiness and delightful crunch to a variety of dishes, from baked goods to savory entrees.
                                    </p>
                                    <p className="product-text">
                                    Experience the delicate crunch and nutty flavor of our premium poppy seeds and elevate your culinary creations to new heights of deliciousness. Whether you're baking, cooking, or experimenting with new recipes, our poppy seeds are sure to inspire your creativity and delight your taste buds. Order now and discover the culinary magic of poppy seeds for yourself!
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="/raw-spices-and-powders">Raw Spice and spice powders</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Why Choose Our Poppy Seeds from Aayush Food Products?</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Superior Quality:</b> We take pride in offering only the highest quality poppy seeds, sourced from reputable producers who adhere to strict standards of cultivation and processing. Our poppy seeds are carefully harvested and packaged to preserve their natural flavor and freshness, ensuring a premium product every time.</li>
                                            <li><b>Nutritional Benefits:  </b>In addition to their culinary appeal, poppy seeds also offer nutritional benefits. Rich in essential minerals like calcium, magnesium, and phosphorus, as well as dietary fiber and healthy fats, they provide a nutritious boost to your diet while enhancing the flavor and texture of your favorite dishes.</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    <h4>Indulge in the Delicate Crunch of Poppy Seeds: A Versatile Culinary Gem</h4>
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Baking:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>Incorporate poppy seeds into your favorite baked goods such as breads, muffins, scones, and cakes for added texture and flavor. Sprinkle them on top of doughs or batter before baking or mix them into the batter for an extra pop of crunchiness.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Salads and Dressings:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                   <p>Add poppy seeds to salads for a nutty crunch or incorporate them into homemade dressings and vinaigrettes for a unique flavor twist. They pair well with leafy greens, fruits, nuts, and cheese, adding texture and complexity to your salads.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/green-chilli-flakes">
                                            <img
                                                src="assets/img/product/thumb/green_chilli_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/green-chilli-flakes">
                                                Green Chilli Flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Poppy;