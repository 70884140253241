import React from 'react';
import Carousel from 'react-grid-carousel'

class RedonionFlakes extends React.Component {
    render() {
        return (
            <div>
                

                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/red_onion_flakes.png" alt="Red Onion Flakes" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/garlic_flakes.png" alt="Red Onion Flakes" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Red Onion Flakes</h2>

                                    <p className="product-text">
                                    From the years of commencement, we are engaged in offering a huge assortment of Dehydrated Red Onion Flakes. This Dehydrated Red Onion Flakes is known for its excellent quality and is based on advanced technology. Dehydrated Red Onion Flakes are available in the market in various sizes.
                                    </p>
                                    <p className="product-text">
                                    At Aayush Food Products, we take pride in delivering the finest quality red onion flakes to meet the diverse culinary needs of our customers. With a commitment to excellence and a passion for flavor, we bring you premium-grade red onion flakes that elevate your dishes to new heights.
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
              <a href="/dehydrated-vegetables-powders">Dehydrated Vegetable Flakes and Powders</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                    <p>
                                    Our signature product, red onion flakes, is meticulously crafted to deliver exceptional taste and aroma. Sourced from the finest red onions and processed using state-of-the-art technology, our flakes retain the natural essence and flavor of fresh onions. Whether you're a professional chef or a home cook, our red onion flakes are the perfect ingredient to enhance the taste of your dishes.
                                    </p>
                                    <div className="list-style2">
                                        <h4>Why Choose Us?</h4>
                                        <ul className="list-unstyled">
                                            <li>Premium Quality: We never compromise on quality, ensuring that our red onion flakes meet the highest standards of excellence.</li>
                                            <li>Exceptional Flavor: Our flakes are packed with the authentic taste and aroma of fresh red onions, adding a burst of flavor to your dishes.</li>
                                        <li>Reliable Supply: With a robust supply chain and efficient logistics, we ensure timely delivery of our products to our customers.</li>
                                        <li>Customer Satisfaction: Your satisfaction is our priority. We strive to exceed your expectations with every purchase, providing unparalleled service and support.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                        Red Onion flakes are a versatile ingredient that can be used in a variety of recipes to add depth of flavor and aroma. Here are some recipes and uses for garlic flakes:
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Garlic Butter</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <li>Mix softened butter with garlic flakes, salt, and parsley.</li>
                                                                    <li>Spread on bread for garlic bread, or use as a flavorful topping for grilled meats and vegetables.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Seasoning Blend</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <li>Combine garlic flakes with onion flakes, dried herbs (such as oregano, thyme, and basil), salt, and pepper.</li>
                                                                    <li>Use as a versatile seasoning blend for roasted vegetables, grilled meats, pasta dishes, and soups.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/carrot-flakes">
                                            <img
                                                src="assets/img/product/thumb/carrot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/carrot-flakes">
                                                Carrot flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/potato-flakes">
                                            <img
                                                src="assets/img/product/thumb/potato_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/potato-flakes">
                                                Potato flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default RedonionFlakes;