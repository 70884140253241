import React from "react";

class SpicePowders extends React.Component{
    render(){
        return(
        <div>
          <div
          className="breadcumb-wrapper" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/spices.jpg)`,
            backgroundSize: 'cover',
        }}
        >
          <div className="container z-index-common">
            <div className="breadcumb-content">
              <h1 className="breadcumb-title" style={{color:'#ffffff'}}>
                Raw Spices & <span className="inner-text">Powders</span>
              </h1>
              <div className="breadcumb-menu-wrap">
                <ul className="breadcumb-menu">
                  <li style={{color:'#ffffff'}}>
                    <a href="/">Home</a>
                  </li>
                  <li style={{color:'#ffffff'}}>
                    Raw Spices and <span className="inner-text">Powders</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>  

        <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> Discover the Essence of Flavor with Our Raw Spices and Spice Powders</span>
                                                <h2 className="sec-title4">Chilli,Pepper,Ginger,Clove...</h2>
                                                <p className="sec-text">Embark on a culinary journey with our exquisite range of raw spices and spice powders, meticulously sourced and expertly crafted to elevate your dishes to new heights of flavor.

Sourced from the finest spice-growing regions around the globe, our raw spices captivate the senses with their freshness, potency, and depth of aroma. From the fiery kick of chili peppers to the warm embrace of cinnamon, each spice in our collection tells a story of tradition, authenticity, and unparalleled quality.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="red-chilli-flakes">
                                                        <img
                                                            src="assets/img/product/thumb/red_chilli.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="red-chilli-flakes">
                                                            Red Chilli Flakes 
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="marathi-moggu">
                                                        <img
                                                            src="assets/img/product/thumb/marathi_moggu.png"
                                                            alt="Marathi moggu"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="marathi-moggu" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="marathi-moggu" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="marathi-moggu">
                                                            Marathi moggu
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="all-spice-jamaica">
                                                        <img
                                                            src="assets/img/product/thumb/all_spice_jamaica.png"
                                                            alt="All spice - Jamaica Pepper, Sarvasuganthi"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="all-spice-jamaica" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="all-spice-jamaica" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="all-spice-jamaica">
                                                            All spice - Jamaica Pepper, Sarvasuganthi
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="black-pepper-whole">
                                                        <img
                                                            src="assets/img/product/thumb/black_pepper.png"
                                                            alt="Black pepper Whole"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="black-pepper-whole" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="black-pepper-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="black-pepper-whole">
                                                            Black pepper Whole
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="tail-pepper">
                                                        <img
                                                            src="assets/img/product/thumb/tail_pepper.png"
                                                            alt="Tailed pepper"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="tail-pepper" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="tail-pepper" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="tail-pepper">
                                                            Tailed pepper
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="white-pepper-whole">
                                                        <img
                                                            src="assets/img/product/thumb/white_pepper.png"
                                                            alt="White pepper whole"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="white-pepper-whole" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="white-pepper-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="white-pepper-whole">
                                                            White pepper whole
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="long-pepper">
                                                        <img
                                                            src="assets/img/product/thumb/long_pepper.png"
                                                            alt="Long pepper"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="long-pepper" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="long-pepper">
                                                            Long pepper
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="clove-whole">
                                                        <img
                                                            src="assets/img/product/thumb/clove.png"
                                                            alt="Clove whole"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="clove-whole" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="clove-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="clove-whole">
                                                            Clove whole
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="cardmom-whole">
                                                        <img
                                                            src="assets/img/product/thumb/cardmom.png"
                                                            alt="Cardamom whole- Black and Green"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="cardmom-whole" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="cardmom-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="cardmom-whole">
                                                            Cardamom whole- Black and Green
                                                            </a>
                                                        </h3>
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="nutmeg-whole">
                                                        <img
                                                            src="assets/img/product/thumb/nutmeg_whole.png"
                                                            alt="Nutmeg whole"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="nutmeg-whole" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="nutmeg-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="nutmeg-whole">
                                                            Nutmeg whole 
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="star-anise">
                                                        <img
                                                            src="assets/img/product/thumb/star_anise.png"
                                                            alt="Star anise"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="star-anise" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="star-anise">
                                                            Star anise 
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="ginger-whole">
                                                        <img
                                                            src="assets/img/product/thumb/ginger_whole.png"
                                                            alt="Ginger Whole"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="ginger-whole" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="ginger-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                      
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="ginger-whole">
                                                            Ginger Whole
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="red-paprika-powder">
                                                        <img
                                                            src="assets/img/product/thumb/red_paprika_powder.png"
                                                            alt="Red Paprika powder"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="red-paprika-powder" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="red-paprika-powder" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="red-paprika-powder">
                                                            Red Paprika powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="sichuan-pepper">
                                                        <img
                                                            src="assets/img/product/thumb/sichuan_pepper.png"
                                                            alt="Sichuan pepper"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="sichuan-pepper" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="sichuan-pepper" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="sichuan-pepper">
                                                            Sichuan pepper
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="poppy-seeds">
                                                        <img
                                                            src="assets/img/product/thumb/poppy-seeds.png"
                                                            alt="Poppy seeds"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="poppy-seeds" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="poppy-seeds" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="poppy-seeds">
                                                            Poppy seeds
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="bird-eye-chilli-whole">
                                                        <img
                                                            src="assets/img/product/thumb/bird_eye_chilli_whole.png"
                                                            alt="Bird eye chilli whole - Red/Green"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="bird-eye-chilli-whole" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="bird-eye-chilli-whole" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="bird-eye-chilli-whole">
                                                            Bird eye chilli whole - Red/Green
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="ajwain-seeds">
                                                        <img
                                                            src="assets/img/product/thumb/ajwain_seeds.png"
                                                            alt="Ajwain seeds"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="ajwain-seeds" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="ajwain-seeds" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="ajwain-seeds">
                                                            Ajwain seeds
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="bhut-jolokia-ghost-pepper">
                                                        <img
                                                            src="assets/img/product/thumb/bhut_jolokia_ghost_pepper.png"
                                                            alt="Bhut Jolokia- Ghost pepper"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="bhut-jolokia-ghost-pepper" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="bhut-jolokia-ghost-pepper" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="bhut-jolokia-ghost-pepper">
                                                            Bhut Jolokia- Ghost pepper 
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </div>
        )
    }
}

export default SpicePowders;