import React from "react";

class HayakuFlavours extends React.Component {
  render() {
    return (
      <div>
        <div
                    className="breadcumb-wrapper" style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/hayaku.jpg)`,
                        backgroundSize: 'cover',
                    }}
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title" style={{color:'#ffffff'}}>
                                Retail Brand <span className="inner-text"> Hayaku Flavours</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li style={{color:'#ffffff'}}>
                                        <a href="/">Home</a>
                                    </li>
                                    <li style={{color:'#ffffff'}}>
                                        <span className="inner-text">Retail Brand - Hayaku Flavours</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> In every flavour, there's a story of nature's bounty waiting to be discovered.</span>
                                                <h2 className="sec-title4"> Enrich Your Soul With Our Tea Infusions</h2>
                                                <p className="sec-text">With the wide range of farmers and suppliers communities throughout India, Now we are ultimately offering healthy packs of herbal tea flavor, food seasoning, and personal care products.  

Drinking coffee and tea causes lots of physical problems. To reduce caffeine consumption and to have a healthy life, Aayush launched a caffeine-free herbal tea infusion in the name of Hayaku.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="https://www.amazon.in/HAYAKU-Flavoring-Biriyani-Enhances-Respiratory/dp/B0CKQYVY6T/ref=sr_1_1?dib=eyJ2IjoiMSJ9.mjViHrsw-64QnWLZYIYiqLKpawSFJ6eAcA5m-mXUFFzONy-e-11tuERcCC0SIaQkZeAoEU0il08qq2_nWlnb5MEjTikpA-R1BUnvMSQ-I1hhIG2FB--vqPDXdtfrwjlo7XA8PKd9uyPR-IoCuQSVvnrr4WaJ2g8_r8QF4DJtyScTfeglqJvvA_k6ML1l1C3P6lziMIw1gh04NLSO21RcQEBXxYznpgc3Ehb8GAd3IL6LfuwI4uGpZX5hVvhfc1hGtP2ZqOUmvRyC-09AjWEyVbFEI9i4Tr_AofXlFIlRCLw.19TQinnSdi-7OuJjx8bAioHAgDCbhBJi65zaTo3njdA&dib_tag=se&keywords=hayaku&qid=1712142756&sr=8-1">
                                                        <img
                                                            src="assets/img/product/thumb/hayaku_pandan_leaves.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="https://www.amazon.in/HAYAKU-Flavoring-Biriyani-Enhances-Respiratory/dp/B0CKQYVY6T/ref=sr_1_1?dib=eyJ2IjoiMSJ9.mjViHrsw-64QnWLZYIYiqLKpawSFJ6eAcA5m-mXUFFzONy-e-11tuERcCC0SIaQkZeAoEU0il08qq2_nWlnb5MEjTikpA-R1BUnvMSQ-I1hhIG2FB--vqPDXdtfrwjlo7XA8PKd9uyPR-IoCuQSVvnrr4WaJ2g8_r8QF4DJtyScTfeglqJvvA_k6ML1l1C3P6lziMIw1gh04NLSO21RcQEBXxYznpgc3Ehb8GAd3IL6LfuwI4uGpZX5hVvhfc1hGtP2ZqOUmvRyC-09AjWEyVbFEI9i4Tr_AofXlFIlRCLw.19TQinnSdi-7OuJjx8bAioHAgDCbhBJi65zaTo3njdA&dib_tag=se&keywords=hayaku&qid=1712142756&sr=8-1" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="https://www.amazon.in/HAYAKU-Flavoring-Biriyani-Enhances-Respiratory/dp/B0CKQYVY6T/ref=sr_1_1?dib=eyJ2IjoiMSJ9.mjViHrsw-64QnWLZYIYiqLKpawSFJ6eAcA5m-mXUFFzONy-e-11tuERcCC0SIaQkZeAoEU0il08qq2_nWlnb5MEjTikpA-R1BUnvMSQ-I1hhIG2FB--vqPDXdtfrwjlo7XA8PKd9uyPR-IoCuQSVvnrr4WaJ2g8_r8QF4DJtyScTfeglqJvvA_k6ML1l1C3P6lziMIw1gh04NLSO21RcQEBXxYznpgc3Ehb8GAd3IL6LfuwI4uGpZX5hVvhfc1hGtP2ZqOUmvRyC-09AjWEyVbFEI9i4Tr_AofXlFIlRCLw.19TQinnSdi-7OuJjx8bAioHAgDCbhBJi65zaTo3njdA&dib_tag=se&keywords=hayaku&qid=1712142756&sr=8-1" target="_blank">
                                                            HAYAKU -Dried Rambha/Pandan Leaves
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                       
                                        
                                        
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
      </div>
    )
  }
}

export default HayakuFlavours;