
import React from "react";
import Carousel from 'react-grid-carousel';


function HomeBlock() {
  return (
    <>
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-9 col-lg-9 col-xl-10">
              <div className="title-area">
                <span className="sec-subtitle"> 100% Natural Dehydrated Food Products</span>
                <h2 className="sec-title4">Vegetables Flakes, Fruits, Leaves, Flowers and Spices</h2>
                <p className="sec-text">Experience the essence of nature with Aayush Food Products - where quality, flavor, and wellness come together in every bite.</p>
              </div>
            </div>
          </div>
          <div className="row flex-nowrap">
            <Carousel cols={3} rows={1} gap={10} loop>
              <Carousel.Item>
                <div className="col-lg-12">
                  <div className="service-style3">
                    <div className="service-img">
                      <img src="assets/img/banner/Home-banner1.png" alt="Dehydrated Vegetables Flakes & Powders" />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title h4">
                        <a className="text-inherit" href="dehydrated-vegetables-powders">
                        Dehydrated Vegetables Flakes & Powders
                        </a>
                      </h3>
                      <p className="service-text">
                        Our dehydrated vegetables and powders are a convenient solution for adding flavor, nutrition, and versatility to your culinary creations. Carefully crafted from premium-quality fresh vegetables, our dehydration process ensures that the natural taste, color, and nutrients are preserved, providing you with a pantry staple that's ready...
                      </p>
                    </div>
                  </div>
                </div>

              </Carousel.Item>
              <Carousel.Item>
              <div className="col-lg-12">
                  <div className="service-style3">
                    <div className="service-img">
                      <img src="assets/img/banner/dried_fruits.png" alt="Spray Dried Fruit Powders" />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title h4">
                        <a className="text-inherit" href="spray-dried-fruits-powder">
                        Spray Dried Fruit Powders
                        </a>
                      </h3>
                      <p className="service-text">
                        Indulge in the natural sweetness and vibrant flavors of our Dehydrated Fruit Powder. Crafted from the finest fruits and carefully dehydrated to preserve their essence, our fruit powder offers a convenient and versatile way to enjoy the goodness of fresh fruit all year round. Whether you're blending smoothies, baking delicious treats, or enhancing .... 
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
              <div className="col-lg-12">
                  <div className="service-style3">
                    <div className="service-img">
                      <img src="assets/img/banner/dried_leaves.png" alt="Dried Leaves & Herbs" />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title h4">
                        <a className="text-inherit" href="dried-leaves-powder">
                        Dried Leaves & Herbs
                        </a>
                      </h3>
                      <p className="service-text">
                        Our Vegetable Powder (Spray Dried) is a premium product crafted from the finest vegetables, carefully processed using advanced spray drying technology to retain maximum flavor, color, and nutritional value. This convenient powder form allows for easy incorporation into a wide range of culinary creations, providing a burst of vegetable goodness ... 
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
              <div className="col-lg-12">
                  <div className="service-style3">
                    <div className="service-img">
                      <img src="assets/img/banner/spices.png" alt="Raw Spices & Spice Powder" />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title h4">
                        <a className="text-inherit" href="raw-spices-and-powders">
                        Raw Spices & Spice Powder
                        </a>
                      </h3>
                      <p className="service-text">
                      In the realm of culinary delights, raw spices and spice powders stand as the vibrant essence that transforms ordinary dishes into extraordinary culinary experiences. These tiny, potent ingredients carry a rich history steeped in culture and tradition, while simultaneously offering a myriad of flavors, aromas, and health benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
              <div className="col-lg-12">
                  <div className="service-style3">
                    <div className="service-img">
                      <img src="assets/img/banner/dried_flowers.png" alt="Dried Flowers & Powders" />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title h4">
                        <a className="text-inherit" href="dried-flowers-and-powders">
                        Dried Flowers & Powders
                        </a>
                      </h3>
                      <p className="service-text">
                      Dried flowers, meticulously preserved to retain their form and fragrance, add a touch of elegance and charm to any setting. Whether used in floral arrangements, potpourri, or DIY crafts, their subtle hues and intricate shapes evoke a sense of serenity and beauty, transforming ordinary spaces into havens of tranquility...
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
              <div className="col-lg-12">
                  <div className="service-style3">
                    <div className="service-img">
                      <img src="assets/img/banner/tbc.png" alt="TBC - Tea Bag Cut" />
                    </div>
                    <div className="service-content">
                      <h3 className="service-title h4">
                        <a className="text-inherit" href="tea-bag-cut">
                        TBC - Tea Bag Cut
                        </a>
                      </h3>
                      <p className="service-text">
                      In the world of tea craftsmanship, the technique of tea bag cutting (TBC) stands as a testament to precision and innovation. Akin to a delicate dance between tradition and modernity, TBC elevates the tea-drinking experience by offering a symphony of flavors and aromas enclosed within meticulously crafted...
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              
            </Carousel>
          </div>
        </div>
      </section>
      <div className="divider-style1  ">
        <div className="divider-icon">
          <img src="assets/img/icons/divider-1-1.svg" alt="icon" />
        </div>
      </div>
    </>
  );
}

export default HomeBlock