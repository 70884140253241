import React from 'react';

function HomeBanner() {
  return (
    <section className=" pt-xl-5 space-extra-bottom">
  <div className="container">
    <div className="row">
      <div className="col-md-6 wow fadeInUp" data-wow-delay="0.2s">
        <div className="banner-style2 mega-hover">
          <div className="banner-img">
            <img src="assets/img/banner/banner-2-1.png" alt="banner" />
          </div>
          <div className="banner-content">
            <span className="banner-label">Vegetables</span>
            <h3 className="banner-title">Dehydrated Onion Flakes</h3>
            <a href="shop.html" className="vs-btn">
              Enquire Now
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <div className="banner-style2 layout2 mega-hover">
          <div className="banner-img">
            <img src="assets/img/banner/banner-2-2.png" alt="banner" />
          </div>
          <div className="banner-content">
            <span className="banner-label">Spices</span>
            <h3 className="banner-title">Dried Garlic Flakes</h3>
            <a href="shop.html" className="vs-btn">
              Enquire Now
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default HomeBanner