
import * as React from 'react';
import { Helmet } from 'react-helmet';
import SliderBlock from '../components/SliderBlock';
import HomeBlock from '../components/HomeBlock';
import HomeAbout from '../components/HomeAbout';
import HomeBanner from '../components/HomeBanner';
import HomeGallery from '../components/HomeGallery';
import HomeTestimonial from '../components/HomeTestimonial';
import HomeBlog from '../components/HomeBlog';
import ModalForm from '../components/ModalForm';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';

class Home extends React.Component{
  render(){
      return (
        <>
          <Helmet>
          <title>Aayush Foods - Dehydrated vegetables | Spray dried fruits powder | Dried leaves and herbs | Spice Powders</title>
          </Helmet>
          <SliderBlock />         
          <HomeBlock />
          <HomeAbout />  
          <HomeBanner />            
          <HomeGallery /> 
          <HomeTestimonial />
          {/* <Appointment />*/}
          <HomeBlog />
          <ModalForm />
        </>
      
      )
  }
}


export default Home;

