
import React, { useState } from 'react';
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import { photos } from '../components/photos';


    const PhotoGallery = () => {

    
        return (
            <div>
                <div
                    className="breadcumb-wrapper "
                    data-bg-src="assets/img/breadcumb/breadcumb-bg-3.jpg"
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Our <span className="inner-text">Portfolio</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        Our <span className="inner-text">Portfolio</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Gallery photos={photos} />
                


            </div>
        )
    }

export default PhotoGallery;