import React from "react";

class DriedFlowers extends React.Component {
    render() {
        return (
            <div>
                <div
                    className="breadcumb-wrapper" style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/flowers.jpg)`,
                        backgroundSize: 'cover',
                    }}
                >
                    <div className="container z-index-common">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Dried Flowers <span className="inner-text"> & Powders</span>
                            </h1>
                            <div className="breadcumb-menu-wrap">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li>
                                    Dried Flowers <span className="inner-text"> and powders</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section
                    className="vs-product-wrapper  "
                    data-bg-src="assets/img/bg/body-bg-1.jpg"
                >
                    <div className="outer-wrap3">
                        <div className="container">
                            <div className="row gx-60">
                                <div className="col-lg-12 col-xl-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-10 col-xl-12">
                                            <div className="title-area">
                                                <span className="sec-subtitle"> The Delicate Allure of Dried Flowers and Powders</span>
                                                <h2 className="sec-title4"> Nature's Beauty, Captured and Preserved</h2>
                                                <p className="sec-text">Step into a world of natural elegance with our exquisite selection of dried flowers and powders, meticulously curated to bring the timeless allure of botanical beauty into your life.

Each delicate blossom in our collection is carefully harvested at its peak, then dried to perfection to preserve its enchanting colors, shapes, and fragrances. From the romantic allure of rose petals to the soothing aroma of lavender buds, our dried flowers captivate the senses and infuse any space with a sense of tranquility and charm.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-hibiscus-flower">
                                                        <img
                                                            src="assets/img/product/p-2-1.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-hibiscus-flower" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-hibiscus-flower">
                                                            Hibiscus Flower
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="shop-details.html">
                                                        <img
                                                            src="assets/img/product/p-2-2.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-eye" />
                                                        </a>
                                                        <a href="#" className="icon-btn">
                                                            <i className="far fa-shopping-cart" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-rose-petals">
                                                            Rose Petals
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-fenugreek">
                                                        <img
                                                            src="assets/img/product/p-2-3.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-fenugreek" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-fenugreek">
                                                            Fenugreek / Methi Leaves  / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-spinach">
                                                        <img
                                                            src="assets/img/product/p-2-4.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-spinach" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-spinach">
                                                            Spinach Leaves / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-bay-leaves">
                                                        <img
                                                            src="assets/img/product/p-2-5.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-bay-leaves" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-bay-leaves">
                                                            Bay leaves / Powder
                                                            </a>
                                                        </h3>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-moringa-leaves">
                                                        <img
                                                            src="assets/img/product/p-2-6.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-moringa-leaves" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-moringa-leaves">
                                                            Moringa leaves / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-curry-leaves">
                                                        <img
                                                            src="assets/img/product/p-2-7.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-curry-leaves" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                      
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-curry-leaves">
                                                            Curry Leaves  / Powder
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-spearmint">
                                                        <img
                                                            src="assets/img/product/p-2-8.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-spearmint" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-spearmint">
                                                            Spearmint
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-peppermint">
                                                        <img
                                                            src="assets/img/product/p-2-9.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-peppermint" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-peppermint">
                                                            Peppermint
                                                            </a>
                                                        </h3>
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <div className="vs-product product-style2">
                                                <div className="product-img">
                                                    <a href="dried-rambha-leaves">
                                                        <img
                                                            src="assets/img/product/p-2-10.png"
                                                            alt="product"
                                                            className="w-100"
                                                        />
                                                    </a>
                                                    <div className="actions">
                                                        <a href="dried-rambha-leaves" className="icon-btn">
                                                            <i className="far fa-heart" />
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div className="product-body">
                                                    <div className="product-content">
                                                        <h3 className="product-title">
                                                            <a className="text-inherit" href="dried-rambha-leaves">
                                                            Rambha/ Pandan Leaves
                                                            </a>
                                                        </h3>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}

export default DriedFlowers;