import React from "react";

class Products extends React.Component {
  render() {
    return (
      <div>
        <div
          className="breadcumb-wrapper "style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/breadcumb/products.jpg)`,
            backgroundSize: 'cover',
        }}
        >
          <div className="container z-index-common">
            <div className="breadcumb-content">
              <h1 className="breadcumb-title">
                Our <span className="inner-text">Products</span>
              </h1>
              <div className="breadcumb-menu-wrap">
                <ul className="breadcumb-menu">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    Our <span className="inner-text">Products</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <section >
          <div className="outer-wrap3">
            <div className="container">
              <div className="row gx-50 flex-row-reverse">
                <div className="col-lg-6 col-xxl">
                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">VEGETABLE POWDERS (DEHYDRATED)</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>

                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">DEHYDRATED VEGETABLES & POWDERS</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">Dehydrated Vegetable Flakes</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">Dehydrated Leaf and Powders Flakes</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                  
                </div>
                <div className="col-lg-6 col-xxl">
                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="spray-dried-fruits-powder">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">DEHYDRATED VEGETABLES & POWDERS</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="spray-dried-fruits-powder" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>

                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">VEGETABLE POWDERS (SPRAY DRIED)</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">FRUIT POWDERS (SPRAY DRIED)</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                  <div className="vs-blog blog-style2">
                    <div className="blog-img">
                      <a href="">
                        <img
                          src="assets/img/blog/blog-2-1.jpg"
                          alt="Blog Thumbnail"
                          className="w-100"
                        />
                      </a>
                    </div>
                    <div className="blog-content">                      
                      <h3 className="blog-title h4">
                        <a href="">SPECIALITY LEAVES</a>
                      </h3>
                      <p className="blog-text">
                        We think your skin should look refshed matter Nourish your outer
                        inner beauty with our essential
                      </p>
                      <a href="" className="vs-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                  
                </div>


                
              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}

export default Products;