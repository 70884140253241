import React from 'react';
import Carousel from 'react-grid-carousel'
import { Helmet } from 'react-helmet';

class Spinach extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Spinach Laves and Powder | Dried Leaves and Herbs | Spray dried fruits powder | Dehydrated vegetables Flakes | Spice Powders</title>
                </Helmet>
                <section className="vs-product-wrapper product-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row gx-60">
                            <div className="col-lg-5">
                                <div className="product-big-img">
                                    <Carousel cols={1} rows={1} loop={1}>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/potato_flakes.png" alt="Spinach Laves and Powder" />
                                    </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <div className="img">
                                        <img src="assets/img/product/potato_flakes.png" alt="Spinach Laves and Powder" />
                                    </div>
                                    </Carousel.Item>
                                    </Carousel>     
   
                                </div>
                            </div>
                            <div className="col-lg-7 align-self-center">
                                <div className="product-about">
                                    <h2 className="product-title">Spinach Laves and Powder</h2>

                                    <p className="product-text">
                                    We are involved in manufacturing and trading Spinach Leaves. These dehydrated spinach leaves are of green color and do not contain contaminants or any added artificial coloring matter.
                                    </p>
                                    <p className="product-text">
                                    Ready to elevate your dishes with the nutritious goodness of Spinach Leaves and Powder? Browse our collection and unlock the culinary possibilities with spinach. With secure online ordering and fast shipping, adding spinach leaves and powder to your pantry has never been easier. Experience the vibrant taste and nutritional benefits of spinach from Aayush Food Products.
                                    </p>
                                    <div className="quantity">
              <label htmlFor="quantity" className="screen-reader-text">
                Category:
              </label>
              <label htmlFor="quantity" className="screen-reader-text">
                <a href="spray-dried-fruits-powder">Spray Dried Fruits Powder</a>
              </label>           
             
             
            </div>
                                    <div className="actions">

                                        <a href="#" className="vs-btn">
                                            Get Price Quote
                                        </a>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul className="nav product-tab1" id="productTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="description-tab"
                                    data-bs-toggle="tab"
                                    href="#description"
                                    role="tab"
                                    aria-controls="description"
                                    aria-selected="true"
                                >
                                    description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link "
                                    id="reviews-tab"
                                    data-bs-toggle="tab"
                                    href="#reviews"
                                    role="tab"
                                    aria-controls="reviews"
                                    aria-selected="false"
                                >
                                    application
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="productTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="description"
                                role="tabpanel"
                                aria-labelledby="description-tab"
                            >
                                <div className="inner-pane">
                                   
                                    <div className="list-style2">
                                        <h4>Why Choose Spinach Laves and Powder from Aayush Food Products?</h4>
                                        <ul className="list-unstyled">
                                            <li><b>Pure Natural Flavor:</b> Our Spinach Leaves and Powder are carefully dried and ground to preserve their natural freshness and flavor. Each leaf and spoonful of powder delivers the distinctive earthy taste and vibrant green color of spinach, enhancing the taste and appearance of your recipes.</li>
                                            <li><b>Nutrient-Rich: </b>Spinach is packed with essential nutrients, including vitamins, minerals, and antioxidants, that promote overall health and well-being. Our dried leaves and powder retain the nutritional benefits of fresh spinach, offering a convenient way to incorporate greens into your diet.</li>
                                        
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade "
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="inner-pane">
                                    <div className="woocommerce-Reviews">
                                        <div className="vs-comments-wrap   ">
                                            <ul className="comment-list">
                                                <li className="review vs-comment-item">
                                                    <p className="text">
                                                    Spinach Laves and Powder are a versatile ingredient that can be used in a variety of recipes to add depth of flavor and aroma. Here are some recipes and uses for Spinach Laves and Powder:
                                                    </p>
                                                    <div className="vs-post-comment">

                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-3.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Green Smoothies: </h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                    <p>Blend spinach powder with fruits, yogurt, and milk for a nutritious green smoothie that's packed with vitamins and minerals. Its mild flavor blends seamlessly with other ingredients, adding a nutrient-rich boost to your morning routine.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="review vs-comment-item">
                                                    <div className="vs-post-comment">
                                                        <div className="comment-avater">
                                                            <img
                                                                src="assets/img/blog/comment-author-1.jpg"
                                                                alt="Comment Author"
                                                            />
                                                        </div>
                                                        <div className="comment-content">
                                                            <h4 className="name h4">Soups and Stews:</h4>

                                                            <div className="list-style2">
                                                                <ul className="list-unstyled">
                                                                   <p>Add dried spinach leaves to soups, stews, and sauces for a pop of color and a dose of nutrients. They wilt quickly in hot liquids, releasing their flavor and enhancing the nutritional content of your dishes.</p>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h3 className="sec-subtitle3">Related Products</h3>
                        <div
                            className="row vs-carousel"
                            data-slide-show={4}
                            data-lg-slide-show={3}
                            data-md-slide-show={2}
                        >
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/garlic-flakes">
                                            <img
                                                src="assets/img/product/thumb/garlic_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/garlic-flakes">
                                                Garlic flakes
                                                </a>
                                            </h3>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/beetroot-flakes">
                                            <img
                                                src="assets/img/product/thumb/beetroot_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/beetroot-flakes">
                                                Beetroot flakes
                                                </a>
                                            </h3>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/red-onion-flakes">
                                            <img
                                                src="assets/img/product/thumb/red_onion_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                       
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/red-onion-flakes">
                                                Red Onion flakes
                                                </a>
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3">
                                <div className="vs-product product-style2">
                                    <div className="product-img">
                                        <a href="/green-chilli-flakes">
                                            <img
                                                src="assets/img/product/thumb/green_chilli_flakes.png"
                                                alt="product"
                                                className="w-100"
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="product-body">
                                        <div className="product-content">
                                            <h3 className="product-title">
                                                <a className="text-inherit" href="/green-chilli-flakes">
                                                Green Chilli Flakes
                                                </a>
                                            </h3>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Spinach;