import React from "react";
import Carousel from 'react-grid-carousel';

function HomeBlog() {
  return (    
<section className=" space-top space-extra-bottom">
  <div className="container">
    <div className="row justify-content-center text-center">
      <div
        className="col-md-9 col-lg-7 col-xl-6 wow fadeInUp wow-animated"
        data-wow-delay="0.2s"
      >
        <div className="title-area">
          <h2 className="sec-title4">Our News Feeds</h2>
          <p className="sec-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text.
          </p>
        </div>
      </div>
    </div>
    <div className="row">
    <Carousel cols={3} rows={1} gap={10} loop>
      <Carousel.Item>
      <div className="col-xl-12">
        <div className="vs-blog blog-style3">
          <span className="blog-number">01 </span>
          <div className="blog-img">
            <a href="#">
              <img
                className="w-100"
                src="assets/img/blog/blog-2-1.jpg"
                alt="Blog"
              />
            </a>
          </div>
          <div className="blog-content">
            <div className="blog-meta">
              <a href="#">
                <i className="fal fa-calendar-alt" />
                12 January, 2023
              </a>
            </div>
            <h3 className="blog-title h4">
              <a href="#">Where Can I Use Lemon Powder?</a>
            </h3>
          </div>
        </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="col-xl-12">
        <div className="vs-blog blog-style3">
          <span className="blog-number">02 </span>
          <div className="blog-img">
            <a href="#">
              <img
                className="w-100"
                src="assets/img/blog/blog-2-1.jpg"
                alt="Blog"
              />
            </a>
          </div>
          <div className="blog-content">
            <div className="blog-meta">
              <a href="#">
                <i className="fal fa-calendar-alt" />
                11 March, 2023
              </a>
            </div>
            <h3 className="blog-title h4">
              <a href="#">Where Can I Use Lemon Powder?</a>
            </h3>
          </div>
        </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="col-xl-12">
        <div className="vs-blog blog-style3">
          <span className="blog-number">03 </span>
          <div className="blog-img">
            <a href="#">
              <img
                className="w-100"
                src="assets/img/blog/blog-2-1.jpg"
                alt="Blog"
              />
            </a>
          </div>
          <div className="blog-content">
            <div className="blog-meta">
              <a href="#">
                <i className="fal fa-calendar-alt" />
                05 Augest, 2023
              </a>
            </div>
            <h3 className="blog-title h4">
              <a href="#">Where Can I Use Lemon Powder?</a>
            </h3>
          </div>
        </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="col-xl-12">
        <div className="vs-blog blog-style3">
          <span className="blog-number">04 </span>
          <div className="blog-img">
            <a href="#">
              <img
                className="w-100"
                src="assets/img/blog/blog-2-1.jpg"
                alt="Blog"
              />
            </a>
          </div>
          <div className="blog-content">
            <div className="blog-meta">
              <a href="#">
                <i className="fal fa-calendar-alt" />
                10 FEBRUARY, 2023
              </a>
            </div>
            <h3 className="blog-title h4">
              <a href="#">Where Can I Use Lemon Powder?</a>
            </h3>
          </div>
        </div>
      </div>
      </Carousel.Item>
      </Carousel>
    </div>
  </div>
</section>

  )
}

export default HomeBlog